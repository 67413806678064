import { createStorageKey } from '@aster/shared/utils/storage';
import { useAuth } from '../../../authentication/AuthProvider';
import { getS3SignedUrl } from '@aster/shared/utils/s3-util';
import { useQuery } from '@tanstack/react-query';

async function generateLabsResultsURL(
  practiceID: string,
  labsOrderID: string,
  onError: () => void
) {
  const url = await getS3SignedUrl({
    Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
    s3key: createStorageKey({
      practiceId: practiceID,
      scope: 'labs',
      name: [labsOrderID, `results.pdf`].join('/'),
    }),
    fileName: `results.pdf`,
    onError,
  });
  return url;
}
export function useLabsPDFUrl(labsOrderID: string | null) {
  const { profile } = useAuth();
  const { data: pdfFileUrl, isLoading: isPDFFileURLBeingGenerated } = useQuery({
    queryKey: ['labs-results-pdf-url', labsOrderID],
    queryFn: () =>
      generateLabsResultsURL(
        profile?.practiceId as string,
        labsOrderID as string,
        () => {
          throw new Error('Failed to generate PDF URL');
        }
      ),
    retry: 8,
    enabled: !!labsOrderID && !!profile?.practiceId,
  });

  return { pdfFileUrl, isPDFFileURLBeingGenerated };
}
