import { Navigate } from 'react-router';

import { CircularProgress } from '@mui/material';

import { usePracticeSettingsQuery } from './queries/use-practice-settings';

import { PracticeSettingsForm } from './PracticeSettingsForm';
import { useUpdatePracticeSettingsMutation } from './mutations/use-update-practice-settings';
import { useUploadPracticeLogoMutation } from './mutations/use-upload-practice-logo';

import Typography from '../../components/Typography';
import { useAuth } from '../../authentication/AuthProvider';

export const PracticeSettings = () => {
  const { profile } = useAuth();
  const { practiceSettings, isLoading } = usePracticeSettingsQuery(
    profile?.practiceId
  );

  const { updatePracticeSettings, isPending: isUpdatePracticeSettingsPending } =
    useUpdatePracticeSettingsMutation();
  const { uploadPracticeLogo, isPending: isUploadPracticeLogoPending } =
    useUploadPracticeLogoMutation();

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  if (!practiceSettings) return <Navigate to="/" />;

  return (
    <div className="px-5 py-16 relative">
      <div className="text-center">
        <Typography
          variant="h1"
          customClass="text-[32px] font-semibold mb-[31px]"
        >
          Practice Settings
        </Typography>
        <PracticeSettingsForm
          practiceSettings={practiceSettings}
          isLoading={isUpdatePracticeSettingsPending}
          isLogoLoading={isUploadPracticeLogoPending}
          onLogoSubmit={(logo) => uploadPracticeLogo(logo)}
          onFormSubmit={(practiceSettings) =>
            updatePracticeSettings(practiceSettings)
          }
        />
      </div>
    </div>
  );
};
