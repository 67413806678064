import {
  PatientInfoDTO,
  PregnancyInfoDTO,
  PregnancyWithChildrenForPatientUpdateDTO,
} from '@aster/shared/dtos/patient';
import { Pregnancy } from './types/patient-profile';
import { CheckboxItem } from './types/question';

export const defaultSexualActivityType: CheckboxItem[] = [
  { value: 'vaginal', text: 'Vaginal intercourse', checked: false },
  { value: 'anal', text: 'Anal intercourse', checked: false },
  { value: 'oral', text: 'Oral sex', checked: false },
  {
    value: 'non_penetrative',
    text: 'Non-penetrative sexual activities',
    checked: false,
  },
  { value: 'sex_toys', text: 'Use of sex toys', checked: false },
  { value: 'prefer_not_say', text: 'Prefer not to say', checked: false },
  { value: 'other', text: 'Other', checked: false },
];

export const defaultSexualProtectionType: CheckboxItem[] = [
  { value: 'condoms', text: 'Condoms', checked: false },
  { value: 'combined_pill', text: 'Combined pill', checked: false },
  { value: 'implant', text: 'Implant', checked: false },
  { value: 'iud', text: 'IUD', checked: false },
  {
    value: 'cycle_tracking_method',
    text: 'Cycle tracking method',
    checked: false,
  },
  { value: 'depo', text: 'Depo', checked: false },
  { value: 'vasectomy', text: 'Vasectomy', checked: false },
  { value: 'tubal_ligation', text: 'Tubal ligation', checked: false },
  { value: 'other', text: 'Other', checked: false },
  { value: 'none', text: 'None', checked: false },
];

export const defaultProblemsWithPeriods: CheckboxItem[] = [
  { value: 'clots', text: 'Clots', checked: false },
  { value: 'diarrhea', text: 'Diarrhea', checked: false },
  { value: 'migraines', text: 'Migraines', checked: false },
  { value: 'dizziness', text: 'Dizziness', checked: false },
  { value: 'pain', text: 'Pain', checked: false },
  { value: 'irregularity', text: 'Irregularity', checked: false },
  { value: 'headache', text: 'Headache', checked: false },
  { value: 'nausea', text: 'Nausea', checked: false },
  { value: 'fainting', text: 'Fainting', checked: false },
  { value: 'heavy_flow', text: 'Heavy flow', checked: false },
  {
    value: 'bleeding_between_periods',
    text: 'Bleeding between periods',
    checked: false,
  },
  { value: 'migraines_with_aura', text: 'Migraines with aura', checked: false },
  { value: 'vomiting', text: 'Vomiting', checked: false },
  { value: 'other', text: 'Other', checked: false },
  { value: 'none', text: 'None', checked: false },
];

export const defaultPreviousInfectionsDiseasesOrSTI: CheckboxItem[] = [
  { value: 'chlamydia', text: 'Chlamydia', checked: false },
  { value: 'gonorrhea', text: 'Gonorrhea', checked: false },
  { value: 'syphilis', text: 'Syphilis', checked: false },
  { value: 'herpes', text: 'Herpes', checked: false },
  { value: 'hpv', text: 'HPV', checked: false },
  { value: 'hiv_aids', text: 'HIV/AIDS', checked: false },
  { value: 'hepatitis_b', text: 'Hepatitis B', checked: false },
  { value: 'trichomoniasis', text: 'Trichomoniasis', checked: false },
  { value: 'other', text: 'Other', checked: false },
  { value: 'none', text: 'None', checked: false },
];

export const defaultChronicMedicalConditions: CheckboxItem[] = [
  {
    value: 'diabetes_mellitus_type_1',
    text: 'Diabetes mellitus, Type 1',
    checked: false,
  },
  {
    value: 'diabetes_mellitus_type_2',
    text: 'Diabetes mellitus, Type 2',
    checked: false,
  },
  { value: 'hypothyroidism', text: 'Hypothyroidism', checked: false },
  { value: 'hyperthyroidism', text: 'Hyperthyroidism', checked: false },
  { value: 'endometriosis', text: 'Endometriosis', checked: false },
  {
    value: 'pcos',
    text: 'Polycystic ovary syndrome (PCOS)',
    checked: false,
  },
  { value: 'asthma', text: 'Asthma', checked: false },
  { value: 'COPD', text: 'COPD', checked: false },
  {
    value: 'ibs',
    text: 'Irritable bowel syndrome (IBS)',
    checked: false,
  },
  { value: 'celiac_disease', text: 'Celiac disease', checked: false },
  { value: 'gerd', text: 'GERD', checked: false },
  { value: 'hypertension', text: 'Hypertension', checked: false },
  { value: 'migraines', text: 'Migraines', checked: false },
  {
    value: 'bleeding_or_clotting_disorders',
    text: 'Bleeding or clotting disorders',
    checked: false,
  },
  { value: 'fibromyalgia', text: 'Fibromyalgia', checked: false },
  { value: 'osteoporosis', text: 'Osteoporosis', checked: false },
  { value: 'osteoarthritis', text: 'Osteoarthritis', checked: false },
  {
    value: 'rheumatoid_arthritis',
    text: 'Rheumatoid arthritis',
    checked: false,
  },
  { value: 'lupus', text: 'Lupus', checked: false },
  { value: 'multiple_sclerosis', text: 'Multiple sclerosis', checked: false },
  {
    value: 'chronic_kidney_disease',
    text: 'Chronic kidney disease',
    checked: false,
  },
  {
    value: 'recurrent_utis',
    text: 'Recurrent UTIs',
    checked: false,
  },
  { value: 'other', text: 'Other', checked: false },
];

export const defaultCurrentMentalHealthProblemOrDiagnosisDetails: CheckboxItem[] =
  [
    { value: 'depression', text: 'Depression', checked: false },
    { value: 'bipolar_disorder', text: 'Bipolar disorder', checked: false },
    {
      value: 'gad',
      text: 'Generalized anxiety disorder (GAD)',
      checked: false,
    },
    { value: 'panic_disorder', text: 'Panic disorder', checked: false },
    {
      value: 'social_anxiety_disorder',
      text: 'Social anxiety disorder',
      checked: false,
    },
    { value: 'specific_phobias', text: 'Specific phobias', checked: false },
    {
      value: 'ptsd',
      text: 'Post-traumatic stress disorder (PTSD)',
      checked: false,
    },
    {
      value: 'acute_stress_disorder',
      text: 'Acute stress disorder',
      checked: false,
    },
    {
      value: 'adjustment_disorders',
      text: 'Adjustment disorders',
      checked: false,
    },
    { value: 'insomnia', text: 'Insomnia', checked: false },
    { value: 'narcolepsy', text: 'Narcolepsy', checked: false },
    {
      value: 'alcohol_use_disorder',
      text: 'Alcohol use disorder',
      checked: false,
    },
    {
      value: 'substance_use_disorder',
      text: 'Substance use disorder',
      checked: false,
    },
    {
      value: 'ocd',
      text: 'Obsessive-compulsive disorder (OCD)',
      checked: false,
    },
    {
      value: 'body_dysmorphic_disorder',
      text: 'Body dysmorphic disorder',
      checked: false,
    },
    { value: 'hoarding_disorder', text: 'Hoarding disorder', checked: false },
    { value: 'anorexia_nervosa', text: 'Anorexia nervosa', checked: false },
    { value: 'bulimia_nervosar', text: 'Bulimia nervosar', checked: false },
    {
      value: 'binge-eating_disorder',
      text: 'Binge-eating disorder',
      checked: false,
    },
    { value: 'schizophrenia', text: 'Schizophrenia', checked: false },
    {
      value: 'schizoaffective_disorder',
      text: 'Schizoaffective disorder',
      checked: false,
    },
    {
      value: 'adhd',
      text: 'Attention-deficit/hyperactivity disorder (ADHD)',
      checked: false,
    },
    {
      value: 'asd',
      text: 'Autism spectrum disorder (ASD)',
      checked: false,
    },
    {
      value: 'bpd',
      text: 'Borderline personality disorder (BPD)',
      checked: false,
    },
    {
      value: 'antisocial_personality_disorder',
      text: 'Antisocial personality disorder',
      checked: false,
    },
    {
      value: 'narcissistic_personality_disorder',
      text: 'Narcissistic personality disorder',
      checked: false,
    },
    {
      value: 'other',
      text: 'Other',
      checked: false,
    },
  ];

export const defaultBloodBorneIllnessDetails: CheckboxItem[] = [
  { value: 'hiv', text: 'HIV', checked: false },
  { value: 'hepatitis', text: 'Hepatitis', checked: false },
  { value: 'other', text: 'Other', checked: false },
];

export const defaultFamilyMedicalConditions: CheckboxItem[] = [
  { value: 'heart_conditions', text: 'Heart conditions', checked: false },
  { value: 'cancer', text: 'Cancer', checked: false },
  { value: 'diabetes', text: 'Diabetes', checked: false },
  { value: 'mental_health', text: 'Mental health', checked: false },
  {
    value: 'maternal_pregnancy_complications',
    text: 'Maternal pregnancy complications',
    checked: false,
  },
  {
    value: 'autoimmune_conditions',
    text: 'Autoimmune conditions e.g. Thyroid disorders',
    checked: false,
  },
  { value: 'genetic_disorders', text: 'Genetic disorders', checked: false },
  { value: 'stroke', text: 'Stroke', checked: false },
  { value: 'hypertension', text: 'Hypertension', checked: false },
  { value: 'bleeding_disorders', text: 'Bleeding disorders', checked: false },
  {
    value: 'autoimmune_conditions',
    text: 'Autoimmune conditions e.g. Thyroid disorders',
    checked: false,
  },
  {
    value: 'genetic_disorders',
    text: 'Genetic disorders e.g. Tay-Sachs, Down’s syndrome',
    checked: false,
  },
  { value: 'other', text: 'Other', checked: false },
];

export const defaultRegularExerciseType: CheckboxItem[] = [
  {
    value: 'light',
    text: 'Light exercise (e.g., walking, yoga)',
    checked: false,
  },
  {
    value: 'moderate',
    text: 'Moderate exercise (e.g., brisk walking, swimming)',
    checked: false,
  },
  {
    value: 'vigorous',
    text: 'Vigorous exercise (e.g., running, cycling, high-intensity)',
    checked: false,
  },
];

export const defaultSpecialNeeds: CheckboxItem[] = [
  {
    value: 'sign_language_interpretation',
    text: 'Sign Language Interpretation',
    checked: false,
  },
  {
    value: 'visual_assistance',
    text: 'Visual Assistance (for visually impaired)',
    checked: false,
  },
  {
    value: 'wheelchair_mobility_assistance',
    text: 'Wheelchair Access/Mobility Assistance',
    checked: false,
  },
  {
    value: 'written_communication_visual_aids',
    text: 'Written Communication/Visual Aids',
    checked: false,
  },
  { value: 'language_support', text: 'Language Support', checked: false },
  {
    value: 'assistance_with_paperwork',
    text: 'Assistance with Paperwork',
    checked: false,
  },
  {
    value: 'sensory_accommodations',
    text: 'Sensory Accommodations',
    checked: false,
  },
  { value: 'quiet_private_space', text: 'Quiet/Private Space', checked: false },
  { value: 'other', text: 'Other', checked: false },
];

export const defaultPreferredCommunicationMethods: CheckboxItem[] = [
  {
    value: 'email_reminders',
    text: 'Email reminders of appointments',
    checked: false,
  },
  {
    value: 'sms_reminders',
    text: 'SMS text reminders of appointments',
    checked: false,
  },
  {
    value: 'marketing_emails',
    text: 'Marketing emails such as newsletters & resources',
    checked: false,
  },
];

export const defaultProcedureNeededForSABorTAB: CheckboxItem[] = [
  { value: 'medication', text: 'Medication', checked: false },
  { value: 'aspiration', text: 'Aspiration', checked: false },
  { value: 'dilation', text: 'Dilation', checked: false },
];

export const defaultPregnancyInfo = {
  pregnancy: {
    dateOfBirthOrEndOfPregnancy: undefined,
    amountOfGestationWeeks: 0,
    pregnancyOutcome: undefined,
    cesareanSectionUrgencyType: undefined,
    cesareanSectionScarringType: undefined,
    complications: undefined,
    locationOfDelivery: undefined,
    locationOfDeliveryOther: undefined,
  },
  children: [
    {
      sex: undefined,
      name: undefined,
      isLiving: undefined,
      breastFeedDetails: undefined,
      feedingCourse: undefined,
      breastFeedDuration: undefined,
      weightUnit: 'lbs',
      ounces: undefined,
      pounds: undefined,
      grams: undefined,
      otherSex: undefined,
    },
  ],
} as PregnancyWithChildrenForPatientUpdateDTO;

export const defaultSexuallyActiveWith: CheckboxItem[] = [
  {
    value: 'men',
    text: 'With men',
    checked: false,
  },
  {
    value: 'women',
    text: 'With women',
    checked: false,
  },
];

export const defaultNationalOrLocalPrograms: CheckboxItem[] = [
  {
    value: 'medicaid',
    text: 'Medicaid/CHIP',
    checked: false,
  },
  { value: 'TANF', text: 'TANF', checked: false },
  { value: 'healthy', text: 'Healthy Start', checked: false },
  { value: 'WIC', text: 'WIC', checked: false },
  { value: 'SNAP', text: 'SNAP', checked: false },
  { value: 'other', text: 'Other', checked: false },
];

export const defaultPregnancyOutcomes: {
  value: PregnancyInfoDTO['pregnancyOutcome'];
  text: string;
}[] = [
  { value: 'sab', text: 'Spontaneous abortion (SAB) or miscarriage' },
  { value: 'tab', text: 'Therapeutic abortion (TAB)' },
  {
    value: 'spontaneous_vaginal_birth',
    text: 'Normal spontaneous vaginal birth',
  },
  {
    value: 'assisted_vaginal_birth',
    text: 'Assisted vaginal birth (e.g., forceps, vacuum)',
  },
  { value: 'cesarean_section', text: 'Cesarean section' },
];

export const defaultPregnanancyLocationOfDelivery = [
  {
    value: 'home',
    text: 'Home',
  },
  {
    value: 'birth_center',
    text: 'Birth center',
  },
  {
    value: 'hospital',
    text: 'Hospital',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

export const defaultOtherPregnancyOutcomes = [
  {
    value: 'pre_eclampsia',
    text: 'Pre-Eclampsia',
    checked: false,
  },
  {
    value: 'gestational_diabetes',
    text: 'Gestational Diabetes',
    checked: false,
  },
  {
    value: 'hypertension',
    text: 'Hypertension',
    checked: false,
  },
  {
    value: 'antenatal_hemorrhage',
    text: 'Antenatal hemorrhage',
    checked: false,
  },
  {
    value: 'postpartum_hemorrhage',
    text: 'Postpartum Hemorrhage',
    checked: false,
  },
  {
    value: 'preterm_rupture_of_membranes',
    text: 'Preterm Rupture of Membranes',
    checked: false,
  },
  {
    value: 'preterm_delivery',
    text: 'Preterm Delivery <37 weeks',
    checked: false,
  },
  {
    value: 'third_degree_perineal_tear',
    text: '3rd Degree Perineal tear',
    checked: false,
  },
  {
    value: 'fourth_degree_perineal_tear',
    text: '4th Degree Perineal tear',
    checked: false,
  },
  {
    value: 'retained_placenta',
    text: 'Retained Placenta',
    checked: false,
  },
  {
    value: 'prenatal_postpartum_anxiety',
    text: 'Prenatal/Postpartum Anxiety',
    checked: false,
  },
  {
    value: 'prenatal_postpartum_depression',
    text: 'Prenatal/Postpartum Depression',
    checked: false,
  },
  {
    value: 'postpartum_psychosis',
    text: 'Postpartum Psychosis',
    checked: false,
  },
  {
    value: 'intrauterine_growth_restriction',
    text: 'Intrauterine growth restriction',
    checked: false,
  },
  {
    value: 'large_for_gestational_age_baby',
    text: 'Large for Gestational Age Baby',
    checked: false,
  },
  {
    value: 'placenta_accreta',
    text: 'Placenta Accreta',
    checked: false,
  },
  {
    value: 'placenta_previa',
    text: 'Placenta Previa',
    checked: false,
  },
  {
    value: 'placental_abruption',
    text: 'Placental Abruption',
    checked: false,
  },
  {
    value: 'other',
    text: 'Other',
    checked: false,
  },
];

export const defaultChildSexOptions = [
  {
    value: 'male',
    text: 'Male',
  },
  {
    value: 'female',
    text: 'Female',
  },
  {
    value: 'other',
    text: 'Other',
  },
];

export const defaultFeedingCourseOptions = [
  {
    value: 'breast',
    text: 'Breast',
  },
  {
    value: 'formula',
    text: 'Formula',
  },
  {
    value: 'both',
    text: 'Both',
  },
];
