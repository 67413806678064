import { Divider, Tabs, tabsClasses } from '@mui/material';
import StyledTab from '../StyledTab';
import { useState } from 'react';
import PersonalInformation from '../forms/PersonalInformation';
import ContactInformation from '../forms/ContactInformation';
import Relationship from '../forms/Relationship';
import Insurance from '../forms/Insurance';
import ContactList from '../forms/ContactList';
import { LinkTab } from '../../../components/Tabs';
import GynecologicHistory from '../forms/GynecologicHistory';
import PregnancyInformation from '../forms/PregnancyInformation';
import ObstetricInformation from '../forms/ObstetricInformation/ObstetricInformation';
import MedicalHistoryInformation from '../forms/MedicalHistory';
import Lifestyle from '../forms/Lifestyle';
import SocialInformation from '../forms/SocialInformation';
import AdditionalInformation from '../forms/AdditionalInformation';
import { useUpdatePatientMutation } from '../mutations/update-patient.mutation';
import { useParams } from 'react-router';
import { usePatientInfoQuery } from '../queries/patient-info.query';

const ProfileTab = () => {
  const [value, setValue] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { patient } = useParams<{ patient: string }>();

  const { patientInfo, isPatientLoading, refetchPatient } =
    usePatientInfoQuery(patient);

  const { updatePatientMutation } = useUpdatePatientMutation(
    patient as string,
    refetchPatient,
    undefined,
    true
  );

  return (
    <div className="flex grow h-full">
      <Tabs
        orientation={'vertical'}
        allowScrollButtonsMobile
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs"
        className="shrink-0 mb-20 relative"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          overflow: 'auto',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <StyledTab label="Personal Information" />
        <StyledTab label="Contact Information" />
        <StyledTab label="Relationship" />
        <StyledTab label="Insurance" />
        <StyledTab label="Contact List" />
        <Divider className="bg-gray-200 ml-4 mr-6" />
        <StyledTab label="Gynecologic History" />
        <StyledTab label="Pregnancy Information" />
        <StyledTab label="Obstetric History" />
        <StyledTab label="Medical History" />
        <StyledTab label="Lifestyle" />
        <StyledTab label="Social Information" />
        <StyledTab label="Additional Information" />
      </Tabs>
      <LinkTab value={value} index={0}>
        <PersonalInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={1}>
        <ContactInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={2}>
        <Relationship
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={3}>
        <Insurance
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={4}>
        <ContactList
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={6}>
        <GynecologicHistory
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={7}>
        <PregnancyInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={8}>
        <ObstetricInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={9}>
        <MedicalHistoryInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={10}>
        <Lifestyle
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={11}>
        <SocialInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
      <LinkTab value={value} index={12}>
        <AdditionalInformation
          patientInfo={patientInfo}
          updatePatientMutation={updatePatientMutation}
          isPatientLoading={isPatientLoading}
        />
      </LinkTab>
    </div>
  );
};

export default ProfileTab;
