import { useMutation } from '@tanstack/react-query';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { AxiosResponse } from 'axios';

const remindAppointmentInner = (id: string) => {
  return axios.post(`/appointments/${id}/remind`);
};

export const useRemindAppointmentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (_: AxiosResponse<any, any>) => void;
  onError?: () => void;
}) => {
  const { showMessage } = useSnackbar();

  const {
    mutate: remindAppointment,
    isPending: isRemindingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['remindAppt'],
    mutationFn: remindAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onError: (error, appt, context) => {
      showMessage({
        message: 'An error occured while sending reminder. Please try again!',
        type: 'error',
      });
      onError?.();
    },
    onSuccess: (_) => {
      showMessage({
        message: `Patient reminder sent!`,
        type: 'success',
      });
      onSuccess?.(_);
    },
  });
  return { remindAppointment, isRemindingAppointment, ...rest };
};
