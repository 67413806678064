import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { useMutation } from '@tanstack/react-query';

const downloadLabOrderLabels = async ({
  orderID,
  collectionDate,
  numberOfLabels = 5,
}: {
  orderID: string;
  collectionDate: string;
  numberOfLabels?: number;
}) => {
  const response = await axios.get<Blob>(
    `/v2/labs/orders/${orderID}/labels/pdf?collectionDate=${collectionDate}&number_of_labels=${numberOfLabels}`,
    {
      responseType: 'blob',
      headers: {
        accept: 'application/pdf',
      },
    }
  );

  return response.data;
};

export const useDownloadLabOrderLabelsMutation = (onSuccess: () => void) => {
  const { showMessage } = useSnackbar();

  const downloadLabOrderLabelsMutation = useMutation({
    mutationFn: downloadLabOrderLabels,
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'labels.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      showMessage({
        message: 'Lab order labels downloaded successfully',
        type: 'success',
      });
      onSuccess();
    },
    onError: () => {
      showMessage({
        message:
          'An error occurred while downloading the lab order labels. Please try again.',
        type: 'error',
      });
    },
  });

  return { downloadLabOrderLabelsMutation };
};
