import StyledDataGrid from '../../components/StyledDataGrid';
import { CreateLabsOrderDialog } from './create-order/CreateLabsOrder';
import { LabOrderDetailsDialog } from './order-details/LabOrderDetails';
import { useEffect, useMemo } from 'react';
import { TryVitalResultEntry } from '@aster/shared/dtos/labs';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { names } from '@aster/shared/utils/names';

import { resultSeverityClassColor } from '../notes/sections/utils';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { useAuth } from '../../authentication/AuthProvider';
import { useSnackbar } from '../../components/Snack';
import { isPatientReadonly } from '../patients/utils/is-patient-readonly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial } from '@fortawesome/pro-light-svg-icons';
import { colors } from '../../theme';
import Typography from '../../components/Typography';
import { ManuallyAddLabResultsDialog } from './manually-add-results/ManuallyAddLabResults';
import { downloadFileFromS3 } from '../documents/utils/s3-files.utils';
import { createStorageKey } from '@aster/shared/utils/storage';
import { usePatientLabOrders } from './queries/query-patient-lab-orders';
import { useLabsStore } from './store/labs.store';
import { usePatientProfileQuery } from '../notes/queries/patient-profile.query';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { cn } from '@aster/client/utils/cn';
import { useParams, useSearchParams } from 'react-router';
import DownloadLabOrderLabels from './create-order/DownloadLabOrderLabels';
import { isSubscriptionStarterPlan } from '../../integrations/stripe/util/is-subscription-starter-plan';
import { Button } from '@aster/client/ui/Button/Button';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@aster/client/ui/Tooltip/Tooltip';
import { UpgradeRequiredTooltipContent } from '../../integrations/stripe/components/UpgradeRequiredTooltipContent';

export default function PatientLabs() {
  const [params] = useSearchParams();
  const { patient: patientID } = useParams();
  const { labOrders, isLoadingOrders } = usePatientLabOrders(patientID);
  const { profile } = useAuth();
  const { showMessage } = useSnackbar();
  const selectedLabOrderID = useLabsStore((state) => state.selectedLabOrderID);
  const openLabOrderDetails = useLabsStore(
    (state) => state.openLabOrderDetails
  );
  const closeLabOrderDetails = useLabsStore(
    (state) => state.closeLabOrderDetails
  );
  const isCreateOrderDialogOpened = useLabsStore(
    (state) => state.isCreateOrderDialogOpened
  );
  const openCreateOrderDialog = useLabsStore(
    (state) => state.openCreateOrderDialog
  );
  const closeCreateOrderDialog = useLabsStore(
    (state) => state.closeCreateOrderDialog
  );
  const manualUploadResultsDialogState = useLabsStore(
    (state) => state.manualUploadResultsDialogState
  );
  const openManualUploadResultsDialog = useLabsStore(
    (state) => state.openManualUploadResultsDialog
  );
  const closeManualUploadResultsDialog = useLabsStore(
    (state) => state.closeManualUploadResultsDialog
  );
  const isDownloadLabOrderLabelsOpened = useLabsStore(
    (state) => state.isDownloadLabOrderLabelsOpened
  );
  const openDownloadLabOrderLabels = useLabsStore(
    (state) => state.openDownloadLabOrderLabels
  );
  const closeDownloadLabOrderLabels = useLabsStore(
    (state) => state.closeDownloadLabOrderLabels
  );
  const { patientProfile } = usePatientProfileQuery(patientID);

  useEffect(() => {
    const order = params.get('order');
    const action = params.get('action');

    if (order) openLabOrderDetails(order);
    else if (action === 'create') openCreateOrderDialog();

    // If there's an `order` id in the URL, we'll open automatically
    // the order details in a modal at mount time - otherwise, if we
    // indicate the `create` action, the wizard will pop-up.
    //
    // eslint-disable-next-line
  }, []);

  const isStarterPlan = isSubscriptionStarterPlan(profile?.stripeProductID);

  const rows = useMemo(() => {
    if (!labOrders) return [];

    return labOrders.map((r) => {
      const outOfRangeResult = (
        r.results?.results as TryVitalResultEntry[] | null
      )?.find(
        (resultEntry) =>
          resultEntry.is_above_max_range ||
          resultEntry.is_above_max_range ||
          resultEntry.interpretation === 'abnormal' ||
          resultEntry.interpretation === 'critical'
      );

      return {
        practiceID: profile?.practiceId ?? '',
        id: r.id,
        orderedBy: r.orderedBy,
        status: names(r.status).sentenceCase,
        isManuallyUploaded: r.status === 'manually_uploaded',
        abnormalResult: outOfRangeResult,
        labTestName: r.labTest.name,
        hasLabResults: !!r.results,
        method: names(r.labTest.method).sentenceCase,
        createdAt: dayjs(r.createdAt).format('MMM DD, YYYY'),
        requisitionFormReady: r.requisitionFormReady,
      };
    });
  }, [labOrders, profile?.practiceId]);

  const columns: GridColDef[] = [
    {
      field: 'abnormalResult',
      headerClassName: 'bg-grayBackground',
      renderHeader: () => (
        <div className="flex justify-center items-center rounded-full bg-asterGray opacity-75 h-5 w-5">
          <PriorityHighIcon className="text-white text-bodySmall" />
        </div>
      ),
      width: 70,
      renderCell: (params: GridCellParams) => {
        const abnormalResultEntry = params.row.abnormalResult as
          | TryVitalResultEntry
          | undefined;
        return (
          abnormalResultEntry && (
            <div
              className={cn(
                'rounded-full h-4 w-4 text-white grid place-content-center',
                resultSeverityClassColor(
                  abnormalResultEntry.interpretation ?? '',
                  'bg'
                )
              )}
            >
              <p className="text-bodySmall">
                {abnormalResultEntry.is_above_max_range ? 'H' : 'L'}
              </p>
            </div>
          )
        );
      },
    },
    {
      field: 'labTestName',
      headerName: 'Lab Test',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'method',
      headerName: 'Test Method',
      headerAlign: 'left',
      flex: 0.7,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      flex: 0.7,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'orderedBy',
      headerName: 'Ordered By',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'createdAt',
      headerName: 'Ordered At',
      headerAlign: 'left',
      flex: 0.7,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'download',
      headerName: 'Download',
      width: 200,
      headerClassName: 'bg-grayBackground',
      renderCell: (params: GridCellParams) => {
        return (
          <div className="flex flex-wrap gap-x-3 items-center">
            <Link
              component="button"
              disabled={!params.row.hasLabResults}
              className="disabled:text-gray-500 disabled:no-underline"
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                const labOrderID = params.row.id;
                const practiceId = params.row.practiceID;

                const s3Key = createStorageKey({
                  practiceId,
                  scope: 'labs',
                  name: [labOrderID, `results.pdf`].join('/'),
                });

                await downloadFileFromS3(
                  s3Key,
                  `${labOrderID}_results.pdf`,
                  () =>
                    showMessage({
                      message:
                        'Failed to download lab results. Please try again later.',
                      type: 'error',
                    })
                );
              }}
            >
              Results
            </Link>
            {!params.row.isManuallyUploaded && (
              <>
                <Link
                  component="button"
                  className="disabled:text-gray-500 disabled:no-underline"
                  disabled={!params.row.requisitionFormReady}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const labOrderID = params.row.id;
                    const practiceId = params.row.practiceID;

                    const s3Key = createStorageKey({
                      practiceId,
                      scope: 'labs',
                      name: [labOrderID, `requisition.pdf`].join('/'),
                    });

                    await downloadFileFromS3(
                      s3Key,
                      `${labOrderID}_requisition.pdf`,
                      () =>
                        showMessage({
                          message: 'The requisiton form is not ready yet',
                          type: 'info',
                          autoClose: 5000,
                        })
                    );
                  }}
                >
                  Requisition Form
                </Link>

                <Link
                  component="button"
                  className="disabled:text-gray-500 disabled:no-underline"
                  disabled={!params.row.requisitionFormReady}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const labOrderID = params.row.id;

                    openDownloadLabOrderLabels(labOrderID);
                  }}
                >
                  Labels
                </Link>
              </>
            )}
          </div>
        );
      },
    },
  ];

  if (isLoadingOrders) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  if (rows.length < 1) {
    return (
      <div className="full-w-container flex-col">
        <header className="py-6 border-b border-gray-200 flex justify-between">
          <Typography variant="h4" text="Lab Orders" />
          <div className="flex items-center gap-4">
            <Button
              variant={'outline'}
              disabled={isPatientReadonly(patientProfile)}
              onClick={() => {
                openManualUploadResultsDialog();
              }}
            >
              Manually Upload
            </Button>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant={'default'}
                    disabled={
                      isPatientReadonly(patientProfile) || isStarterPlan
                    }
                    onClick={() => {
                      openCreateOrderDialog();
                    }}
                  >
                    Create Lab Order
                  </Button>
                </TooltipTrigger>
                {isStarterPlan && <UpgradeRequiredTooltipContent />}
              </Tooltip>
            </TooltipProvider>
          </div>
        </header>
        <div className="flex flex-col items-center py-6">
          <FontAwesomeIcon icon={faVial} size="4x" color={colors.main} />
          <div className="flex flex-col items-center mb-6 mt-6">
            <Typography variant="h3" customClass="font-normal">
              {patientProfile?.name}'s lab order list is empty
            </Typography>
            <Typography variant="bodyMedium" customClass="text-gray-500">
              Let's get started by creating their first lab order.
            </Typography>
          </div>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant={'default'}
                  disabled={isPatientReadonly(patientProfile) || isStarterPlan}
                  onClick={() => {
                    openCreateOrderDialog();
                  }}
                >
                  Create Lab Order
                </Button>
              </TooltipTrigger>
              {isStarterPlan && <UpgradeRequiredTooltipContent />}
            </Tooltip>
          </TooltipProvider>
        </div>
        <CreateLabsOrderDialog
          fullWidth
          open={isCreateOrderDialogOpened}
          onClose={closeCreateOrderDialog}
          handleClose={closeCreateOrderDialog}
        />
        {manualUploadResultsDialogState !== 'closed' && (
          <ManuallyAddLabResultsDialog
            fullWidth
            open={true}
            handleClose={closeManualUploadResultsDialog}
          />
        )}
      </div>
    );
  }

  return (
    <section className="flex flex-col full-w-container overflow-auto">
      <div className="flex justify-between mb-4 flex-wrap gap-y-2">
        <p className="text-h4 text-black font-semibold m-0">Lab Orders</p>

        <div className="flex items-center gap-4">
          <Button
            variant={'outline'}
            disabled={isPatientReadonly(patientProfile)}
            onClick={() => {
              openManualUploadResultsDialog();
            }}
          >
            Manually Upload
          </Button>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant={'default'}
                  disabled={isPatientReadonly(patientProfile) || isStarterPlan}
                  onClick={() => {
                    openCreateOrderDialog();
                  }}
                >
                  Create Lab Order
                </Button>
              </TooltipTrigger>
              {isStarterPlan && <UpgradeRequiredTooltipContent />}
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <div className="w-full h-fit mb-2 mt-4 grid">
        <StyledDataGrid
          rows={rows}
          pageSizeOptions={[5, 10, 25, 50]}
          loading={isLoadingOrders}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          isRowSelectable={() => false}
          onCellClick={(cell) => {
            if (cell.field === 'download') {
              return;
            }
            if (
              cell.row.id &&
              (cell.row.status === 'Completed' ||
                cell.row.status === 'Manually uploaded')
            ) {
              openLabOrderDetails(cell.row.id);
            }
          }}
          getRowClassName={() => 'cursor-pointer'}
        />
      </div>

      {Boolean(selectedLabOrderID) && (
        <LabOrderDetailsDialog
          fullWidth
          open={Boolean(selectedLabOrderID)}
          onClose={() => closeLabOrderDetails()}
          handleClose={() => closeLabOrderDetails()}
          labOrderID={selectedLabOrderID ?? undefined}
          patientID={patientProfile?.patientID}
        />
      )}
      <CreateLabsOrderDialog
        fullWidth
        open={isCreateOrderDialogOpened}
        onClose={closeCreateOrderDialog}
        handleClose={closeCreateOrderDialog}
      />
      {manualUploadResultsDialogState !== 'closed' && (
        <ManuallyAddLabResultsDialog
          fullWidth
          open={true}
          handleClose={closeManualUploadResultsDialog}
        />
      )}
      {isDownloadLabOrderLabelsOpened != null && (
        <DownloadLabOrderLabels
          orderID={isDownloadLabOrderLabelsOpened}
          open={true}
          handleClose={closeDownloadLabOrderLabels}
        />
      )}
    </section>
  );
}
