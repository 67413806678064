import { SubscriptionStatus } from '../stripe';

export type PracticeDTO = {
  id: string;
  name: string;
  email: string | null;
  slug: string | null;
  type: keyof typeof PracticeType;
  extraType: string | null;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  phoneNumber: string | null;
  dosespotClinicId: string | null;
  dosespotClinicKey: string | null;
  logoUrl: string | null;
  consensusCustomerKey?: string;
  consensusGroupName?: string;
  stripeCustomerID?: string;
  stripeProductID?: string;
  stripeSeatCount: number;
  stripeSubscriptionStatus: keyof typeof SubscriptionStatus;
  isLegacyCustomer: boolean;
};

export type SubscriptionDTO = Pick<
  PracticeDTO,
  'stripeCustomerID' | 'stripeSubscriptionStatus'
>;

export type UpdatePracticeInput = Partial<PracticeDTO>;

export const PracticeType = {
  birthCenter: 'birth_center',
  prenatalCareCenter: 'prenatal_care_center',
  homeBirthPractice: 'home_birth_practice',
  doulaPractice: 'doula_practice',
  lactationConsultant: 'lactation_consultant',
  other: 'other',
};

export const PracticeTypeDisplayNames = {
  [PracticeType.birthCenter]: 'Birth Center',
  [PracticeType.prenatalCareCenter]: 'Prenatal Care Center',
  [PracticeType.homeBirthPractice]: 'Home Birth Practice',
  [PracticeType.doulaPractice]: 'Doula Practice',
  [PracticeType.lactationConsultant]: 'Lactation Consultant',
  [PracticeType.other]: 'Other',
};
