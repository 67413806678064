import React, { useState } from 'react';
import { signUp } from 'aws-amplify/auth';
import AsterLogo from '../assets/aster';
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
  styled,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BasicTextfield from '../components/Textfield';
import { colors } from '../theme';
import { logAnalyticEvent } from '../app/firebase';
import { useNavigate, useLocation } from 'react-router';

const CssInput = styled(FilledInput)({
  '&.MuiFilledInput-root': {
    color: colors.gray,
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: colors.white,
    border: '1px solid',
    width: '100%',
    borderColor: colors.grayLight,
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
    '&.Mui-error': {
      borderColor: colors.red,
    },
    '&.MuiFormLabel-root': {
      color: colors.primary,
      fontWeight: 600,
    },
  },
});

const CssLabel = styled(InputLabel)({
  '&.MuiInputLabel-root': {
    '&.Mui-focused': {
      fontWeight: 600,
      color: colors.primary,
    },
    '&.Mui-error': {
      color: colors.red,
    },
  },
});

const Upgrade = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const email = queryParams.get('email');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    username: email || '',
    password: '',
  });

  const handleFormInput = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleUpgrade = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const { username, password } = state;
    signUp({
      username,
      password,
      options: {
        userAttributes: {
          email: username,
          family_name: 'lastname',
          given_name: 'firstname',
          'custom:practiceName': 'practice',
        },
      },
    })
      .then(() => {
        localStorage.setItem('user', username);
        logAnalyticEvent('authentication', 'upgrade', username);
        setLoading(false);
      })
      .then(() => navigate('/verify'))
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen pt-8 md:pt-[5%] bg-grayBackground">
      <div className="align-middle mb-10">
        <AsterLogo />
      </div>
      <div className="flex flex-col items-center lg:w-1/3 sm:w-full  min-h-min rounded-[20px] bg-white p-11 max-w-[480px]">
        <div className="text-h4 text-primary font-semibold">
          Let's upgrade your account
        </div>
        <div className="text-body text-gray-500 mb-9">
          Please enter a new password to continue
        </div>

        <BasicTextfield
          id="email"
          variant="filled"
          label="Work email"
          name="username"
          value={state.username}
          onChange={handleFormInput}
          error={!!error}
          classes={`my-6`}
        />

        <FormControl variant="filled" fullWidth>
          <CssLabel htmlFor="filled-adornment-password" error={!!error}>
            Password
          </CssLabel>
          <CssInput
            id="filled-adornment-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            disableUnderline={true}
            value={state.password}
            onChange={handleFormInput}
            error={!!error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {error && (
          <div className="grid grid-cols-6 w-full ml-10">
            <p className="text-red-500 !content-start col-span-5">{error}</p>
          </div>
        )}
        <button
          className="bg-primary mt-5 px-12 py-3 rounded-full text-white hover:opacity-80"
          disabled={loading}
          onClick={handleUpgrade}
        >
          {loading && <i className="fa fa-spinner px-6 fa-spin text-white"></i>}
          {!loading && <p>Continue</p>}
        </button>
      </div>
      <div className="flex gap-2 text-center text-gray-500 sm:text-left bg-grayBackground my-4 sm:my-6">
        <span>Already upgraded?</span>
        <span
          className="text-secondary cursor-pointer hover:opacity-80"
          onClick={() => navigate('/signin')}
        >
          Sign in
        </span>
      </div>
    </div>
  );
};

export default Upgrade;
