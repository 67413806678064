import Typography from '../../../components/Typography';
import {
  MedicalHistoryFields,
  useMedicalHistoryInfoForm,
} from '../hooks/useMedicalHistoryInfoForm';
import { CircularProgress } from '@mui/material';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { Label } from '@aster/client/ui/Label/Label';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import {
  defaultBloodBorneIllnessDetails,
  defaultChronicMedicalConditions,
  defaultCurrentMentalHealthProblemOrDiagnosisDetails,
  defaultFamilyMedicalConditions,
} from '../defaults';
import { Input } from '@aster/client/ui/Input/Input';
import { Button } from '@aster/client/ui/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InputMask } from '@react-input/mask';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import { LAST_SAVED_MEDICAL_HISTORY_INFORMATION } from '../constants';
import dayjs from 'dayjs';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';

const MedicalHistoryInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        medicalHistoryInfoForm.handleSubmit();
      },
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(
      LAST_SAVED_MEDICAL_HISTORY_INFORMATION
    );
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const medicalHistoryInfoForm = useMedicalHistoryInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save(value)?.then(() => {
          storePreference(
            LAST_SAVED_MEDICAL_HISTORY_INFORMATION,
            dayjs().toISOString()
          );
          setDebounceValue(1000);
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: Partial<MedicalHistoryFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);
    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={medicalHistoryInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                medicalHistoryInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Medical History"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <medicalHistoryInfoForm.Field
                    name="hasChronicMedicalConditions"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any current or previous chronic medical
                          conditions?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasChronicMedicalConditions = value === 'yes';
                            field.handleChange(hasChronicMedicalConditions);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values
                    .hasChronicMedicalConditions && (
                    <div className="flex bg-gray-100">
                      <div className="flex flex-col gap-2 p-4 w-full">
                        <FormControl className="gap-2">
                          <medicalHistoryInfoForm.Field
                            name="chronicMedicalConditions"
                            children={(field) => (
                              <div className="flex flex-wrap gap-2 max-w-[800px]">
                                <div className="flex flex-col gap-4">
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Endocrine
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(0, 4)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Reproductive Health
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(4, 6)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2 w-full sm:w-auto">
                                    <Label className="text-black font-semibold">
                                      Respiratory
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(6, 8)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Gastrointestinal
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(8, 11)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Cardiovascular
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(11, 12)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Neurological
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(12, 13)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Hematological
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(13, 14)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Musculoskeletal
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(14, 17)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Autoimmune
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(17, 20)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Renal Conditions
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(20, 22)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex flex-col gap-2 w-full sm:w-auto">
                                    <Label className="text-black font-semibold">
                                      Other Conditions
                                    </Label>
                                    {defaultChronicMedicalConditions
                                      .slice(22)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </FormControl>
                        {medicalHistoryInfoForm.state.values.chronicMedicalConditions?.find(
                          (item) => item.value === 'other'
                        )?.checked && (
                          <medicalHistoryInfoForm.Field
                            name="chronicMedicalConditionsDetailsText"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Input
                                  placeholder="Other chronic medical conditions"
                                  type="text"
                                  name={field.name}
                                  value={field.state.value ?? ''}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      medicalHistoryInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <medicalHistoryInfoForm.Field
                    name="hasCurrentMentalHealthProblemOrDiagnosis"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any current mental health problems or
                          diagnoses?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasCurrentMentalHealthProblemOrDiagnosis =
                              value === 'yes';
                            field.handleChange(
                              hasCurrentMentalHealthProblemOrDiagnosis
                            );
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values
                    .hasCurrentMentalHealthProblemOrDiagnosis && (
                    <div className="flex bg-gray-100">
                      <div className="flex flex-col gap-2 p-4">
                        <FormControl className="gap-2">
                          <medicalHistoryInfoForm.Field
                            name="currentMentalHealthProblemOrDiagnosisDetails"
                            children={(field) => (
                              <div className="flex flex-wrap gap-2 max-w-[800px]">
                                <div className="flex flex-col gap-4">
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Mood Disorders
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(0, 2)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Anxiety Disorders
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(2, 6)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Trauma and Stressor-Related
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(6, 9)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Sleep-Wake Disorders
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(9, 11)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Substance-Related
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(11, 13)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div className="flex flex-col gap-4">
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Obsessive-Compulsive and Related
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(13, 16)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Eating Disorders
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(16, 19)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Psychotic Disorders
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(19, 21)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Neurodevelopmental
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(21, 23)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <Label className="text-black font-semibold">
                                      Personality Disorders
                                    </Label>
                                    {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                      .slice(23, 26)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                    <div className="flex flex-col gap-2">
                                      <Label className="text-black font-semibold">
                                        Other Mental Health Problems
                                      </Label>
                                      {defaultCurrentMentalHealthProblemOrDiagnosisDetails
                                        .slice(26)
                                        .map((condition) => (
                                          <div
                                            key={condition.value}
                                            className="flex gap-2 relative"
                                          >
                                            <Checkbox
                                              id={`${field.name}-${condition.value}`}
                                              key={condition.value}
                                              checked={
                                                field.state.value?.find(
                                                  (item) =>
                                                    item.value ===
                                                    condition.value
                                                )?.checked
                                              }
                                              onCheckedChange={(value) => {
                                                if (value === 'indeterminate')
                                                  return;
                                                const updatedItems =
                                                  field.state.value?.map(
                                                    (item) =>
                                                      item.value ===
                                                      condition.value
                                                        ? {
                                                            ...item,
                                                            checked: value,
                                                          }
                                                        : item
                                                  );
                                                if (updatedItems) {
                                                  field.handleChange(
                                                    updatedItems
                                                  );
                                                  medicalHistoryInfoForm.handleSubmit();
                                                }
                                              }}
                                              disabled={readonly}
                                            />
                                            <Label
                                              className="cursor-pointer text-aster-secondary font-normal"
                                              htmlFor={`${field.name}-${condition.value}`}
                                            >
                                              {condition.text}
                                            </Label>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                          {medicalHistoryInfoForm.state.values.currentMentalHealthProblemOrDiagnosisDetails?.find(
                            (item) => item.value === 'other'
                          )?.checked && (
                            <medicalHistoryInfoForm.Field
                              name="currentMentalHealthProblemOrDiagnosisDetailsText"
                              children={(field) => (
                                <FormControl className="w-full">
                                  <Input
                                    placeholder="Other mental health problems"
                                    type="text"
                                    name={field.name}
                                    value={field.state.value ?? ''}
                                    onChange={(evt) =>
                                      field.handleChange(evt.target.value)
                                    }
                                    onBlur={(evt) => {
                                      if (field.state.meta.isDirty) {
                                        medicalHistoryInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                </FormControl>
                              )}
                            />
                          )}
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <medicalHistoryInfoForm.Field
                    name="hasBloodBorneIllness"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any blood-borne illnesses such as
                          HIV/Hepatitis?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasBloodBorneIllness = value === 'yes';
                            field.handleChange(hasBloodBorneIllness);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-point text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values.hasBloodBorneIllness && (
                    <div className="flex bg-gray-100">
                      <div className="flex flex-col gap-2 p-4 w-full">
                        <FormControl className="gap-2">
                          <medicalHistoryInfoForm.Field
                            name="bloodBorneIllnessDetails"
                            children={(field) => (
                              <div className="flex flex-wrap gap-2 max-w-[800px]">
                                <div className="flex flex-col gap-4">
                                  <div className="flex flex-col gap-2">
                                    {defaultBloodBorneIllnessDetails
                                      .slice(0, 3)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </FormControl>
                        {medicalHistoryInfoForm.state.values.bloodBorneIllnessDetails?.find(
                          (item) => item.value === 'other'
                        )?.checked && (
                          <medicalHistoryInfoForm.Field
                            name="bloodBorneIllnessDetailsText"
                            children={(field) => (
                              <FormControl className="w-full">
                                <Input
                                  placeholder="Other blood-borne illnesses"
                                  type="text"
                                  name={field.name}
                                  value={field.state.value ?? ''}
                                  onChange={(evt) =>
                                    field.handleChange(evt.target.value)
                                  }
                                  onBlur={(evt) => {
                                    if (field.state.meta.isDirty) {
                                      medicalHistoryInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </FormControl>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <medicalHistoryInfoForm.Field
                    name="hasPreviousSurgery"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Have you had any previous surgeries?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasPreviousAbdominalSurgery = value === 'yes';
                            field.handleChange(hasPreviousAbdominalSurgery);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values.hasPreviousSurgery && (
                    <medicalHistoryInfoForm.Field
                      name="hasPreviousSurgeryDetails"
                      children={(field) => (
                        <FormControl>
                          <Input
                            placeholder="Surgery details"
                            className="max-w-[250px]"
                            type="text"
                            name={field.name}
                            value={field.state.value ?? ''}
                            onChange={(evt) =>
                              field.handleChange(evt.target.value)
                            }
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                medicalHistoryInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}

                  <medicalHistoryInfoForm.Field
                    name="hasPreviousAbdominalSurgery"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Have you had any previous abdominal surgeries?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasPreviousAbdominalSurgery = value === 'yes';
                            field.handleChange(hasPreviousAbdominalSurgery);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  <medicalHistoryInfoForm.Field
                    name="hasMajorAccidentsOrInjuries"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Have you been involved in any major accidents e.g. car
                          accidents?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasMajorAccidentsOrInjuries = value === 'yes';
                            field.handleChange(hasMajorAccidentsOrInjuries);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values
                    .hasMajorAccidentsOrInjuries && (
                    <medicalHistoryInfoForm.Field
                      name="majorAccidentsOrInjuriesDetails"
                      children={(field) => (
                        <FormControl>
                          <Input
                            placeholder="Major injury details"
                            className="max-w-[250px]"
                            type="text"
                            name={field.name}
                            value={field.state.value ?? ''}
                            onChange={(evt) =>
                              field.handleChange(evt.target.value)
                            }
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                medicalHistoryInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  <medicalHistoryInfoForm.Field
                    name="isCurrentlyTakingMedicationsOrSuplements"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Are you currently taking any medications or
                          supplements?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const isCurrentlyTakingMedicationsOrSuplements =
                              value === 'yes';
                            field.handleChange(
                              isCurrentlyTakingMedicationsOrSuplements
                            );
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values
                    .isCurrentlyTakingMedicationsOrSuplements && (
                    <medicalHistoryInfoForm.Field
                      name="currentMedicationsOrSupplementsList"
                      children={(field) => (
                        <div className="flex flex-col items-start">
                          <div className="flex flex-col gap-6">
                            {field.state.value?.map((medication, i) => (
                              <div
                                className="flex items-start gap-4"
                                key={`${medication.name}-i`}
                              >
                                <div className="flex flex-col gap-2">
                                  <div className="flex items-center gap-4">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <div className="flex flex-col">
                                        <Label>Start Date</Label>
                                        <InputMask
                                          component={Input}
                                          mask="MM/DD/YYYY"
                                          separate
                                          showMask
                                          replacement={{
                                            D: /\d/,
                                            M: /\d/,
                                            Y: /\d/,
                                          }}
                                          defaultValue={
                                            field.state.value?.[i].startDate ??
                                            ''
                                          }
                                          onChange={(e) => {
                                            const updatedMedications =
                                              field.state.value?.map(
                                                (item, index) =>
                                                  index === i
                                                    ? {
                                                        ...item,
                                                        startDate:
                                                          e.target.value,
                                                      }
                                                    : item
                                              );
                                            if (updatedMedications) {
                                              field.handleChange(
                                                updatedMedications
                                              );
                                              medicalHistoryInfoForm.handleSubmit();
                                            }
                                          }}
                                          disabled={readonly}
                                        />
                                      </div>
                                    </LocalizationProvider>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <div className="flex flex-col">
                                        <Label>End Date</Label>
                                        <InputMask
                                          component={Input}
                                          name={field.name}
                                          mask="MM/DD/YYYY"
                                          separate
                                          showMask
                                          onChange={(e) => {
                                            const updatedMedications =
                                              field.state.value?.map(
                                                (item, index) =>
                                                  index === i
                                                    ? {
                                                        ...item,
                                                        endDate: e.target.value,
                                                      }
                                                    : item
                                              );
                                            if (updatedMedications) {
                                              field.handleChange(
                                                updatedMedications
                                              );
                                            }
                                          }}
                                          replacement={{
                                            D: /\d/,
                                            M: /\d/,
                                            Y: /\d/,
                                          }}
                                          defaultValue={
                                            field.state.value?.[i].endDate ?? ''
                                          }
                                          disabled={readonly}
                                        />
                                      </div>
                                    </LocalizationProvider>
                                  </div>
                                  <div className="flex gap-4">
                                    <div className="flex flex-col">
                                      <Label>Medication Name</Label>
                                      <Input
                                        placeholder="Medication Name"
                                        defaultValue={
                                          field.state.value?.[i].name ?? ''
                                        }
                                        onBlur={(e) => {
                                          const updatedMedications =
                                            field.state.value?.map(
                                              (item, index) =>
                                                index === i
                                                  ? {
                                                      ...item,
                                                      name: e.target.value,
                                                    }
                                                  : item
                                            );
                                          if (updatedMedications) {
                                            field.handleChange(
                                              updatedMedications
                                            );
                                            medicalHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="flex flex-col">
                                      <Label>Frequency</Label>
                                      <Input
                                        placeholder="Frequency"
                                        defaultValue={
                                          field.state.value?.[i].frequency ?? ''
                                        }
                                        onBlur={(e) => {
                                          const updatedMedications =
                                            field.state.value?.map(
                                              (item, index) =>
                                                index === i
                                                  ? {
                                                      ...item,
                                                      frequency: e.target.value,
                                                    }
                                                  : item
                                            );
                                          if (updatedMedications) {
                                            field.handleChange(
                                              updatedMedications
                                            );
                                            medicalHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex gap-4">
                                    <div className="flex flex-col">
                                      <Label>Dosage</Label>
                                      <Input
                                        placeholder="Dosage"
                                        defaultValue={
                                          field.state.value?.[i].dosage ?? ''
                                        }
                                        onBlur={(e) => {
                                          const updatedMedications =
                                            field.state.value?.map(
                                              (item, index) =>
                                                index === i
                                                  ? {
                                                      ...item,
                                                      dosage: e.target.value,
                                                    }
                                                  : item
                                            );
                                          if (updatedMedications) {
                                            field.handleChange(
                                              updatedMedications
                                            );
                                            medicalHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="flex flex-col">
                                      <Label>Purpose</Label>
                                      <Input
                                        placeholder="Purpose"
                                        defaultValue={
                                          field.state.value?.[i].purpose ?? ''
                                        }
                                        onBlur={(e) => {
                                          const updatedMedications =
                                            field.state.value?.map(
                                              (item, index) =>
                                                index === i
                                                  ? {
                                                      ...item,
                                                      purpose: e.target.value,
                                                    }
                                                  : item
                                            );
                                          if (updatedMedications) {
                                            field.handleChange(
                                              updatedMedications
                                            );
                                            medicalHistoryInfoForm.handleSubmit();
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  className="p-0"
                                  type="button"
                                  variant={'text'}
                                  onClick={() => {
                                    const updatedMedications =
                                      field.state.value?.filter(
                                        (item, index) => index !== i
                                      );
                                    if (updatedMedications) {
                                      field.handleChange(updatedMedications);
                                      medicalHistoryInfoForm.handleSubmit();
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-red-500"
                                  />
                                </Button>
                              </div>
                            ))}
                          </div>
                          <Button
                            type="button"
                            className="mt-1 p-0"
                            variant={'text'}
                            onClick={() => {
                              const updatedMedications = [
                                ...field.state.value,
                                {
                                  name: '',
                                  dosage: '',
                                  frequency: '',
                                  startDate: '',
                                  endDate: '',
                                  purpose: '',
                                  method: '',
                                },
                              ];
                              field.handleChange(updatedMedications);
                            }}
                          >
                            + Add Medication
                          </Button>
                        </div>
                      )}
                    />
                  )}
                  <medicalHistoryInfoForm.Field
                    name="hasAllergies"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any allergies?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasAllergies = value === 'yes';
                            field.handleChange(hasAllergies);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values.hasAllergies && (
                    <medicalHistoryInfoForm.Field
                      name="allergyList"
                      children={(field) => (
                        <div className="flex flex-col gap-2">
                          {field.state.value?.map((allergy, i) => (
                            <div
                              className="flex gap-4"
                              key={`${allergy.name}-i`}
                            >
                              <Input
                                placeholder="Allergen"
                                defaultValue={field.state.value?.[i].name}
                                onBlur={(e) => {
                                  const updatedAllergyList =
                                    field.state.value?.map((item, index) =>
                                      index === i
                                        ? { ...item, name: e.target.value }
                                        : item
                                    );
                                  if (updatedAllergyList) {
                                    field.handleChange(updatedAllergyList);
                                    medicalHistoryInfoForm.handleSubmit();
                                  }
                                }}
                              />
                              <Input
                                placeholder="Reaction"
                                defaultValue={field.state.value?.[i].reaction}
                                onBlur={(e) => {
                                  const updatedAllergyList =
                                    field.state.value?.map((item, index) =>
                                      index === i
                                        ? { ...item, reaction: e.target.value }
                                        : item
                                    );
                                  if (updatedAllergyList) {
                                    field.handleChange(updatedAllergyList);
                                    medicalHistoryInfoForm.handleSubmit();
                                  }
                                }}
                              />
                              <Button
                                className="p-0"
                                variant={'text'}
                                onClick={() => {
                                  const updatedAllergyList =
                                    field.state.value?.filter(
                                      (item, index) => index !== i
                                    );
                                  if (updatedAllergyList) {
                                    field.handleChange(updatedAllergyList);
                                    medicalHistoryInfoForm.handleSubmit();
                                  }
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-red-500"
                                />
                              </Button>
                            </div>
                          ))}

                          <div className="flex gap-2">
                            <Button
                              type="button"
                              className="p-0"
                              variant={'text'}
                              onClick={() => {
                                const updatedAllergyList = field.state.value
                                  ? [
                                      ...field.state.value,
                                      { name: '', reaction: '' },
                                    ]
                                  : [{ name: '', reaction: '' }];
                                field.handleChange(updatedAllergyList);
                              }}
                            >
                              + Add Allergy
                            </Button>
                          </div>
                        </div>
                      )}
                    />
                  )}
                  <medicalHistoryInfoForm.Field
                    name="hasFamilyMedicalConditions"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Do you have any family members with chronic medical
                          conditions?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasFamilyMedicalConditions = value === 'yes';
                            field.handleChange(hasFamilyMedicalConditions);
                            medicalHistoryInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {medicalHistoryInfoForm.state.values
                    .hasFamilyMedicalConditions && (
                    <>
                      <div className="flex bg-gray-100 p-4">
                        <div className="flex flex-col gap-2 p-4 w-full">
                          <FormControl className="gap-2 w-full">
                            <medicalHistoryInfoForm.Field
                              name="familyMedicalConditions"
                              children={(field) => (
                                <div className="flex flex-wrap gap-2">
                                  <div className="flex flex-col gap-2">
                                    {defaultFamilyMedicalConditions
                                      .slice(0, 7)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    {defaultFamilyMedicalConditions
                                      .slice(7)
                                      .map((condition) => (
                                        <div
                                          key={condition.value}
                                          className="flex gap-2 relative"
                                        >
                                          <Checkbox
                                            id={`${field.name}-${condition.value}`}
                                            key={condition.value}
                                            checked={
                                              field.state.value?.find(
                                                (item) =>
                                                  item.value === condition.value
                                              )?.checked
                                            }
                                            onCheckedChange={(value) => {
                                              if (value === 'indeterminate')
                                                return;
                                              const updatedItems =
                                                field.state.value?.map((item) =>
                                                  item.value === condition.value
                                                    ? {
                                                        ...item,
                                                        checked: value,
                                                      }
                                                    : item
                                                );
                                              if (updatedItems) {
                                                field.handleChange(
                                                  updatedItems
                                                );
                                                medicalHistoryInfoForm.handleSubmit();
                                              }
                                            }}
                                            disabled={readonly}
                                          />
                                          <Label
                                            className="cursor-pointer text-aster-secondary font-normal"
                                            htmlFor={`${field.name}-${condition.value}`}
                                          >
                                            {condition.text}
                                          </Label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            />
                          </FormControl>
                          {medicalHistoryInfoForm.state.values.familyMedicalConditions?.find(
                            (item) => item.value === 'other'
                          )?.checked && (
                            <medicalHistoryInfoForm.Field
                              name="familyMedicalConditionsDetailsText"
                              children={(field) => (
                                <FormControl>
                                  <Input
                                    placeholder="Other family medical conditions"
                                    type="text"
                                    name={field.name}
                                    value={field.state.value ?? ''}
                                    onChange={(evt) =>
                                      field.handleChange(evt.target.value)
                                    }
                                    onBlur={(evt) => {
                                      if (field.state.meta.isDirty) {
                                        medicalHistoryInfoForm.handleSubmit();
                                      }
                                    }}
                                    disabled={readonly}
                                  />
                                </FormControl>
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default MedicalHistoryInformation;
