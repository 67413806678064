import { StateCreator } from 'zustand';
import { CreateLabsSlice } from './create-labs.slice';
import { ManuallyUploadLabsSlice } from './manually-upload-labs.slice';

type LabsRootState = {
  selectedLabOrderID: string | null;
  isCreateOrderDialogOpened: boolean;
  isDownloadLabOrderLabelsOpened: string | null;
};

type LabsRootAction = {
  openLabOrderDetails: (labsOrderID: string) => void;
  closeLabOrderDetails: () => void;
  openCreateOrderDialog: () => void;
  closeCreateOrderDialog: () => void;
  openDownloadLabOrderLabels: (labOrderID: string) => void;
  closeDownloadLabOrderLabels: () => void;
};

export type LabsRootSlice = LabsRootState & LabsRootAction;

export const createLabsRootSlice: StateCreator<
  LabsRootSlice & CreateLabsSlice & ManuallyUploadLabsSlice,
  [],
  [],
  LabsRootSlice
> = (set) => ({
  selectedLabOrderID: null,
  isCreateOrderDialogOpened: false,
  isDownloadLabOrderLabelsOpened: null,
  isLabsOrderCreationWizardOpen: false,
  openLabOrderDetails: (labsOrderID) => {
    set({
      selectedLabOrderID: labsOrderID,
      manualUploadResultsDialogState: 'closed',
      isCreateOrderDialogOpened: false,
      isDownloadLabOrderLabelsOpened: null,
    });
  },
  closeLabOrderDetails: () => {
    set({ selectedLabOrderID: null, isCheckoutQuestionsVisible: false });
  },
  openCreateOrderDialog: () => {
    set({
      isCreateOrderDialogOpened: true,
      isDownloadLabOrderLabelsOpened: null,
      selectedLabOrderID: null,
      manualUploadResultsDialogState: 'closed',
    });
  },
  closeCreateOrderDialog: () => {
    set({
      isCreateOrderDialogOpened: false,
      isCheckoutQuestionsVisible: false,
    });
  },
  openDownloadLabOrderLabels: (labOrderId) => {
    set({
      isCreateOrderDialogOpened: false,
      isDownloadLabOrderLabelsOpened: labOrderId,
      selectedLabOrderID: null,
      manualUploadResultsDialogState: 'closed',
    });
  },
  closeDownloadLabOrderLabels: () => {
    set({
      isDownloadLabOrderLabelsOpened: null,
    });
  },
});
