import { Button } from '@aster/client/ui/Button/Button';
import { Label } from '@aster/client/ui/Label/Label';
import { CreatePatientDTO, PatientInfoDTO } from '@aster/shared/dtos/patient';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { usePersonalInfoForm } from '../../hooks/usePersonalInfoForm';
import { useGynecologicHistoryInfoForm } from '../../hooks/useGynecologicHistoryInfoForm';
import { useAdditionalInfoForm } from '../../hooks/useAdditionalInfoForm';
import { useSocialInfoForm } from '../../hooks/useSocialInfoForm';
import { useLifestyleInfoForm } from '../../hooks/useLifestyleInfoForm';
import { useMedicalHistoryInfoForm } from '../../hooks/useMedicalHistoryInfoForm';
import { useObstetricHistoryInfoForm } from '../../hooks/useObstetricHistoryInfoForm';
import { usePregnancyInfoForm } from '../../hooks/usePregnancyInfoForm';
import { useContactInfoForm } from '../../hooks/useContactInfoForm';
import { useReleationshipInfoForm } from '../../hooks/useRelationshipInfoForms';
import { useInsuranceInfoForm } from '../../hooks/useInsuranceInfoForm';
import { useContactListInfoForm } from '../../hooks/useContactListInfoForm';
import { useParams } from 'react-router';
import { useCommunicationPreferencesForm } from '../../hooks/useCommunicationPreferencesForm';

type ProfileSaveButtonProps = {
  lastSavedString: string;
  updatePatientMutation: UseMutationResult<
    AxiosResponse<any, any>,
    Error,
    CreatePatientDTO | Partial<PatientInfoDTO>,
    unknown
  >;
  form: ReturnType<
    | typeof usePersonalInfoForm
    | typeof useContactInfoForm
    | typeof useReleationshipInfoForm
    | typeof useInsuranceInfoForm
    | typeof useContactListInfoForm
    | typeof useGynecologicHistoryInfoForm
    | typeof usePregnancyInfoForm
    | typeof useObstetricHistoryInfoForm
    | typeof useMedicalHistoryInfoForm
    | typeof useLifestyleInfoForm
    | typeof useSocialInfoForm
    | typeof useAdditionalInfoForm
    | typeof useCommunicationPreferencesForm
  >;
};

export const ProfileSaveButton = ({
  lastSavedString,
  updatePatientMutation,
  form,
}: ProfileSaveButtonProps) => {
  const { patient } = useParams<{ patient: string }>();
  return (
    patient && (
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
        children={([canSubmit, isSubmitting]) => (
          <div className="flex items-center justify-end bg-white z-10 p-4 absolute bottom-0 right-0 gap-4 w-full">
            <Label>{lastSavedString}</Label>
            <Button
              isLoading={updatePatientMutation.status === 'pending'}
              className="rounded-[8px]"
              disabled={
                updatePatientMutation.status === 'pending' ||
                !canSubmit ||
                isSubmitting
              }
              type="submit"
              onClick={() => form.handleSubmit()}
            >
              Save
            </Button>
          </div>
        )}
      ></form.Subscribe>
    )
  );
};
