import { useState, ReactNode, useRef } from 'react';
import {
  SelectChangeEvent,
  Select,
  FormControl,
  styled,
  MenuItem,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router';
import { colors } from '../../../theme';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import ButtonType from '../../../components/Button';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { TemplateTypes, getMappedTemplates } from './templateConstants';
import { useAuth } from '../../../authentication/AuthProvider';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { isPatientReadonly } from '../../patients/utils/is-patient-readonly';

const CustomSelect = styled(Select)({
  '& .MuiSelect-outlined': {
    backgroundColor: colors.primary,
    color: colors.white,
    borderColor: colors.primary,
    borderRadius: 8,
    padding: '5px 15px',
    height: 20,
    '&:focus': {
      backgroundColor: colors.primary,
      borderRadius: 8,
    },
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: 15,
    fontStyle: 'normal',
  },
  '& .MuiSvgIcon-root.MuiSelect-icon': {
    color: colors.white,
  },
});

type TemplateProps = {
  noteInfo: string;
  signed: boolean;
  label?: string;
  handleChangeNote: (note: string) => void;
  buttons: ButtonType[];
  headerButtons?: ReactNode | ReactNode[];
  useLargeNotes?: boolean;
};

type ButtonType = {
  value: string;
  text: string;
};

const Options = [
  { value: 'history', text: 'History' },
  { value: 'labs', text: 'Labs/Ultrasounds' },
  { value: 'imaging', text: 'Imaging' },
  { value: 'soap', text: 'SOAP' },
  { value: 'laborSOAP', text: 'Labor SOAP' },
  { value: 'laborAdmissionNote', text: 'Labor Admission Note' },
  { value: 'initialOB', text: 'Initial OB History' },
  { value: 'physicalPrenatal', text: 'Physical Exam Prenatal' },
  { value: 'prenatalIniVis', text: 'Prenatal Initial Visit' },
  { value: 'prenatalIniVisExt', text: 'Inital Prenatal Visit Extended' },
  { value: 'week1014', text: '10-14 week' },
  { value: 'week1519', text: '15-19 week' },
  { value: 'week2025', text: '20-25 week' },
  { value: 'week2629', text: '26-29 week' },
  { value: 'week3034', text: '30-34 week' },
  { value: 'week3536', text: '35-36 week' },
  { value: 'week3740', text: '37-40 week' },
  { value: 'week4042', text: '40-42 week' },
  { value: 'followup', text: 'Follow Up' },
  { value: 'ppVisit', text: 'Postpartum' },
];

const TemplateComponent = ({
  noteInfo,
  signed,
  label,
  handleChangeNote,
  buttons,
  headerButtons,
  useLargeNotes = true,
}: TemplateProps) => {
  const { patient } = useParams();
  const [type, setType] = useState<TemplateTypes | ''>('');
  const { profile } = useAuth();
  const quillRef = useRef<ReactQuill>(null);
  const disclaimerKey = `disclaimerSeen_${profile ? profile.email : ''}`;
  const hasDisclaimerBeenSeen = localStorage.getItem(disclaimerKey) === 'true';

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const { patientProfile } = usePatientProfileQuery(patient as string);

  const mappedTemplates = getMappedTemplates(patientProfile, profile);

  const handleChangeTemplateType = (
    event: SelectChangeEvent<TemplateTypes>
  ) => {
    const typeValue = event.target.value as TemplateTypes;
    setType(typeValue);
    displayTemplate(typeValue);
    if (!hasDisclaimerBeenSeen) {
      setShowDisclaimer(true);
    }
  };

  const handleClick = (type: TemplateTypes) => {
    setType(type);
    displayTemplate(type);
  };

  const displayTemplate = (templateType: TemplateTypes) => {
    const template = mappedTemplates[templateType] || '';
    handleChangeNote(`${noteInfo ?? ''} ${template}`);
    setType('');
  };

  const handleDisclaimer = () => {
    setShowDisclaimer(false);
    localStorage.setItem(disclaimerKey, 'true');
  };

  return (
    <CollapsibleComponent
      label={label ? label : 'Notes'}
      actions={headerButtons}
    >
      <div className="flex flex-col self-stretch p-2.5 rounded-b-lg bg-grayCard w-full">
        <div className="mb-4">
          <ReactQuill
            className={useLargeNotes ? 'notes' : ''}
            theme="snow"
            style={{
              backgroundColor: signed ? colors.grayBackground : colors.white,
              width: 'auto',
              height: 'auto',
              marginRight: 20,
              fontSize: 20,
            }}
            value={noteInfo}
            onChange={(value, _delta, source) => {
              if (source === 'user') {
                handleChangeNote(value);
              }
            }}
            readOnly={signed || isPatientReadonly(patientProfile)}
            ref={quillRef}
          />
        </div>
        {!signed && patientProfile?.status !== 'archived' && (
          <div className="flex gap-2 flex-row">
            <FormControl className="flex-grow md:flex-grow-0 w-[150px]">
              <CustomSelect
                placeholder="Template"
                className="rounded-full bg-transparent text-body font-semibold border-1 w-full md:w-auto"
                defaultValue="Template"
                variant="outlined"
                value={type}
                displayEmpty
                onChange={
                  handleChangeTemplateType as (
                    event: SelectChangeEvent<unknown>,
                    child: ReactNode
                  ) => void
                }
                inputProps={{ 'aria-label': 'Without label' }}
                renderValue={() => {
                  return <p className="px-2">Template</p>;
                }}
              >
                <MenuItem disabled value="">
                  <em>Template</em>
                </MenuItem>
                {Options.map((item: { value: string; text: string }) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <div className="flex flex-wrap gap-2 w-full md:w-auto">
              {buttons.map((item) => (
                <ButtonType
                  key={item.value}
                  variant="outlined"
                  text={item.text}
                  icon
                  classes="max-h-8 rounded-[8px]"
                  specificIcon={
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  }
                  onClick={() => {
                    handleClick(item.value as TemplateTypes);
                    if (!hasDisclaimerBeenSeen) {
                      setShowDisclaimer(true);
                    }
                  }}
                />
              ))}
            </div>
          </div>
        )}
        <ConfirmationModal
          open={showDisclaimer}
          title="Disclaimer"
          description="Templates are written with a view of normal findings as standard, it is the providers responsibility to check and make the relevant changes to any templates used during charting."
          confirm="Ok"
          dismiss=""
          handleClose={() => setShowDisclaimer(false)}
          handleConfirm={handleDisclaimer}
        />
      </div>
    </CollapsibleComponent>
  );
};

export default TemplateComponent;
