import { FormApi, ReactFormApi, ValidationError } from '@tanstack/react-form';
import { TryVitalResultEntry } from '@aster/shared/dtos/labs';
import { z } from 'zod';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Input } from '@aster/client/ui/Input/Input';
import { Combobox } from '@aster/client/ui/Combobox/Combobox';
import { Textarea } from '@aster/client/ui/Textarea/Textarea';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { Label } from '@aster/client/ui/Label/Label';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import Typography from '../../../../components/Typography';
import { ResultsInterpretation } from '@aster/shared/dtos/labs';
import { InputMask } from '@react-input/mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faPlus } from '@fortawesome/pro-light-svg-icons';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';
import { useGetStaffs } from '../../../calendar/queries/use-get-staffs.query';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useLabsStore } from '../../store/labs.store';
import { Button } from '@aster/client/ui/Button/Button';

type FillResultsFormProps = {
  form: FormApi<
    {
      patientID: string | undefined;
      panelName: string;
      interpretation: ResultsInterpretation;
      orderedBy: string;
      date_collected: string;
      date_received: string;
      date_reported: string;
      laboratory: string;
      results: TryVitalResultEntry[];
      notes: string;
    },
    () => {
      validate(
        {
          value,
        }: {
          value: unknown;
        },
        fn: z.ZodType
      ): ValidationError;
      validateAsync(
        {
          value,
        }: {
          value: unknown;
        },
        fn: z.ZodType
      ): Promise<ValidationError>;
    }
  > &
    ReactFormApi<
      {
        patientID: string | undefined;
        panelName: string;
        interpretation: ResultsInterpretation;
        orderedBy: string;
        date_collected: string;
        date_received: string;
        date_reported: string;
        laboratory: string;
        results: TryVitalResultEntry[];
        notes: string;
      },
      () => {
        validate(
          {
            value,
          }: {
            value: unknown;
          },
          fn: z.ZodType
        ): ValidationError;
        validateAsync(
          {
            value,
          }: {
            value: unknown;
          },
          fn: z.ZodType
        ): Promise<ValidationError>;
      }
    >;
};

export default function FillResultsForm({ form }: FillResultsFormProps) {
  const { staffMembers, areStaffLoading } = useGetStaffs();
  const staffOptions = useMemo(() => {
    if (staffMembers && !areStaffLoading) {
      return staffMembers?.map((r) => ({
        value: r.id,
        label: `${r.firstName} ${r.lastName}`,
      }));
    }
    return [];
  }, [areStaffLoading, staffMembers]);
  const processedResults = useLabsStore((state) => state.processedResults);

  return (
    <div className="col-start-2 col-end-4 3xl:col-end-3 pl-6 pr-1 overflow-y-auto flex flex-col">
      <div className="flex flex-col">
        <Typography variant="h5" text="Lab Panel" customClass="mb-3" />
        <form.Field
          name="panelName"
          validators={{
            onBlur: z
              .string()
              .min(1, { message: 'The panel name is required' }),
          }}
          children={(field) => (
            <FormControl className="mb-4">
              <Label>Name of Panel</Label>
              <Input
                name={field.name}
                placeholder="Panel Name"
                id={field.name}
                value={field.state.value}
                onBlur={field.handleBlur}
                hasError={field.state.meta.errors.length > 0}
                errorMessageId={`err-${field.name}`}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.handleChange(event.target.value)
                }
              />
              <FormError id={`err-${field.name}`}>
                {field.state.meta.errors?.join('\r')}
              </FormError>
            </FormControl>
          )}
        ></form.Field>
        <form.Field
          name="interpretation"
          validators={{
            onChange: z.enum(['normal', 'abnormal', 'critical']),
          }}
          children={(field) => (
            <FormControl className="mb-4" id="portal-root">
              <Label>
                Panel Result
                {processedResults?.metadata.interpretation &&
                processedResults?.metadata.interpretation ===
                  field.state.value ? (
                  <FontAwesomeIcon
                    className="text-green-600 ml-1"
                    icon={faSparkles}
                    size="sm"
                  />
                ) : undefined}
              </Label>

              <Select
                name={field.name}
                value={field.state.value}
                defaultValue="normal"
                onValueChange={(value) =>
                  field.handleChange(value as ResultsInterpretation)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="normal">Normal</SelectItem>
                    <SelectItem value="abnormal">Abnormal</SelectItem>
                    <SelectItem value="critical">Critical</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
          )}
        />
        <form.Field
          name="orderedBy"
          children={(field) => (
            <FormControl className="mb-4">
              <Label>Ordered By</Label>
              <Combobox
                items={staffOptions}
                placeholder="Who made this order"
                emptyMessage="There are no providers to select"
                onSelect={(value) => field.handleChange(value)}
                hasError={field.state.meta.errors.length > 0}
                errorMessageId={`err-${field.name}`}
                loading={areStaffLoading}
                popoverAlign="start"
              />

              <FormError id={`err-${field.name}`}>
                {field.state.meta.errors?.join('\r')}
              </FormError>
            </FormControl>
          )}
        />

        <div className="grid grid-cols-3 items-center w-full gap-x-3">
          <form.Field
            name="date_collected"
            children={(field) => (
              <FormControl className="mb-4">
                <Label>
                  Date Collected
                  {processedResults?.metadata.date_collected &&
                  processedResults?.metadata.date_collected ===
                    field.state.value ? (
                    <FontAwesomeIcon
                      className="text-green-600 ml-1"
                      icon={faSparkles}
                      size="sm"
                    />
                  ) : undefined}
                </Label>
                <InputMask
                  component={Input}
                  name={field.name}
                  mask="MM/DD/YYYY"
                  separate
                  showMask
                  onBlur={field.handleBlur}
                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                  value={field.state.value}
                  onChange={(event) => field.handleChange(event.target.value)}
                  placeholder="MM/DD/YYYY"
                />
              </FormControl>
            )}
          ></form.Field>
          <form.Field
            name="date_received"
            children={(field) => (
              <FormControl className="mb-4">
                <Label>
                  Date Received
                  {processedResults?.metadata.date_received &&
                  field.state.value ===
                    processedResults?.metadata.date_received ? (
                    <FontAwesomeIcon
                      icon={faSparkles}
                      size="sm"
                      className="text-green-600 ml-1"
                    />
                  ) : undefined}
                </Label>
                <InputMask
                  component={Input}
                  name={field.name}
                  mask="MM/DD/YYYY"
                  showMask
                  separate
                  onBlur={field.handleBlur}
                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                  value={field.state.value}
                  onChange={(event) => field.handleChange(event.target.value)}
                  placeholder="MM/DD/YYYY"
                />
              </FormControl>
            )}
          ></form.Field>
          <form.Field
            name="date_reported"
            children={(field) => (
              <FormControl className="mb-4">
                <Label>
                  Date Reported
                  {processedResults?.metadata.date_reported &&
                  processedResults?.metadata.date_reported ===
                    field.state.value ? (
                    <FontAwesomeIcon
                      icon={faSparkles}
                      size="sm"
                      className="text-green-600 ml-1"
                    />
                  ) : undefined}
                </Label>
                <InputMask
                  component={Input}
                  name={field.name}
                  mask="MM/DD/YYYY"
                  showMask
                  separate
                  onBlur={field.handleBlur}
                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                  value={field.state.value}
                  onChange={(event) => field.handleChange(event.target.value)}
                  placeholder="DD/MM/YYYY"
                />
              </FormControl>
            )}
          ></form.Field>
        </div>
      </div>
      <Divider className="border-gray-200 my-5" />
      <div className="flex flex-col">
        <Typography variant="h6" text="Tests Included" customClass="mb-3" />
        <form.Field name="results" mode="array">
          {(field) => (
            // Lab results entry
            <div className="flex flex-col">
              {field.state.value.map((_, i) => {
                return (
                  <div
                    key={i}
                    className="grid gap-x-4 grid-cols-[minmax(200px,1fr)_minmax(0,1fr)_minmax(0,1fr)_minmax(0,1fr)_minmax(0,1fr)_minmax(0,1fr)_40px] items-center"
                  >
                    <form.Field
                      name={`results[${i}].name`}
                      validators={{
                        onBlur: z.string().min(1, { message: '*Required' }),
                      }}
                    >
                      {(subfield) => (
                        <FormControl className="mb-4">
                          <Label>
                            Test Name
                            {processedResults?.results[i]?.name &&
                            processedResults?.results[i]?.name ===
                              subfield.state.value ? (
                              <FontAwesomeIcon
                                icon={faSparkles}
                                size="sm"
                                className="text-green-600 ml-1"
                              />
                            ) : undefined}
                          </Label>
                          <Input
                            name={subfield.name}
                            placeholder="Test Name"
                            id={subfield.name}
                            hasError={subfield.state.meta.errors.length > 0}
                            value={subfield.state.value}
                            onBlur={subfield.handleBlur}
                            errorMessageId={`err-${i}-${subfield.name}`}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => subfield.handleChange(event.target.value)}
                          />
                          <FormError id={`err-${i}-${subfield.name}`}>
                            {subfield.state.meta.errors?.join('\r')}
                          </FormError>
                        </FormControl>
                      )}
                    </form.Field>
                    <form.Field
                      name={`results[${i}].result`}
                      validators={{
                        onBlur: z.string().min(1, { message: '*Required' }),
                      }}
                    >
                      {(subfield) => (
                        <FormControl className="mb-4">
                          <Label>
                            Result
                            {processedResults?.results[i]?.result &&
                            processedResults?.results[i]?.result ===
                              subfield.state.value ? (
                              <FontAwesomeIcon
                                icon={faSparkles}
                                size="sm"
                                className="text-green-600 ml-1"
                              />
                            ) : undefined}
                          </Label>
                          <Input
                            name={subfield.name}
                            placeholder="0"
                            hasError={subfield.state.meta.errors.length > 0}
                            errorMessageId={`err-${i}-${subfield.name}`}
                            id={subfield.name}
                            value={subfield.state.value}
                            onBlur={subfield.handleBlur}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => subfield.handleChange(event.target.value)}
                          />
                          <FormError id={`err-${i}-${subfield.name}`}>
                            {subfield.state.meta.errors?.join('\r')}
                          </FormError>
                        </FormControl>
                      )}
                    </form.Field>
                    <form.Field name={`results[${i}].unit`}>
                      {(subfield) => (
                        <FormControl className="mb-4">
                          <Label>
                            Units
                            {processedResults?.results[i]?.unit &&
                            processedResults?.results[i]?.unit ===
                              subfield.state.value ? (
                              <FontAwesomeIcon
                                icon={faSparkles}
                                size="sm"
                                className="text-green-600 ml-1"
                              />
                            ) : undefined}
                          </Label>
                          <Input
                            name={subfield.name}
                            placeholder="0"
                            id={subfield.name}
                            value={subfield.state.value ?? undefined}
                            onBlur={subfield.handleBlur}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => subfield.handleChange(event.target.value)}
                          />
                        </FormControl>
                      )}
                    </form.Field>
                    <form.Field name={`results[${i}].interpretation`}>
                      {(subfield) => (
                        <FormControl className="mb-4" id="portal-root">
                          <Label>
                            Panel Result
                            {processedResults?.results[i]?.interpretation &&
                            processedResults?.results[i]?.interpretation ===
                              subfield.state.value ? (
                              <FontAwesomeIcon
                                className="text-green-600 ml-1"
                                icon={faSparkles}
                                size="sm"
                              />
                            ) : undefined}
                          </Label>
                          <Select
                            name={subfield.name}
                            value={subfield.state.value ?? undefined}
                            defaultValue="normal"
                            onValueChange={(value) =>
                              subfield.handleChange(
                                value as ResultsInterpretation
                              )
                            }
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select an option" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectItem value="normal">Normal</SelectItem>
                                <SelectItem value="abnormal">
                                  Abnormal
                                </SelectItem>
                                <SelectItem value="critical">
                                  Critical
                                </SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </FormControl>
                      )}
                    </form.Field>
                    <form.Field name={`results[${i}].min_range_value`}>
                      {(subfield) => (
                        <FormControl className="mb-4">
                          <Label>
                            Min Range
                            {processedResults?.results[i]?.min_range_value &&
                            processedResults?.results[i]?.min_range_value ===
                              subfield.state.value ? (
                              <FontAwesomeIcon
                                icon={faSparkles}
                                size="sm"
                                className="text-green-600 ml-1"
                              />
                            ) : undefined}
                          </Label>
                          <Input
                            name={subfield.name}
                            placeholder="0"
                            type="number"
                            id={subfield.name}
                            value={subfield.state.value ?? undefined}
                            onBlur={subfield.handleBlur}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              subfield.handleChange(
                                Number.parseFloat(event.target.value)
                              )
                            }
                          />
                        </FormControl>
                      )}
                    </form.Field>
                    <form.Field name={`results[${i}].max_range_value`}>
                      {(subfield) => (
                        <FormControl className="mb-4">
                          <Label>
                            Max Range
                            {processedResults?.results[i]?.max_range_value &&
                            processedResults?.results[i]?.max_range_value ===
                              subfield.state.value ? (
                              <FontAwesomeIcon
                                icon={faSparkles}
                                size="sm"
                                className="text-green-600 ml-1"
                              />
                            ) : undefined}
                          </Label>
                          <Input
                            name={subfield.name}
                            placeholder="0"
                            type="number"
                            id={subfield.name}
                            value={subfield.state.value ?? undefined}
                            onBlur={subfield.handleBlur}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              subfield.handleChange(
                                Number.parseFloat(event.target.value)
                              )
                            }
                          />
                        </FormControl>
                      )}
                    </form.Field>
                    {i != 0 && (
                      <IconButton onClick={() => field.removeValue(i)}>
                        <FontAwesomeIcon
                          icon={faClose}
                          size="sm"
                          color="black"
                          className="aspect-square"
                        />
                      </IconButton>
                    )}
                  </div>
                );
              })}
              <Button
                variant="outline"
                size="sm"
                className="w-fit"
                onClick={() =>
                  field.pushValue({
                    name: '',
                    value: -1,
                    result: '0',
                    unit: '',
                    interpretation: 'normal',
                    min_range_value: 0,
                    max_range_value: 0,
                    type: 'numeric',
                  })
                }
              >
                <FontAwesomeIcon
                  className="text-aster-secondary group-hover:text-primary"
                  icon={faPlus}
                />{' '}
                Add Test
              </Button>
            </div>
          )}
        </form.Field>
      </div>
      <Divider className="border-gray-200 my-5" />
      <form.Field
        name="notes"
        children={(field) => (
          <FormControl className="mb-4">
            <Label>Notes</Label>
            <Textarea
              name={field.name}
              className="min-h-20"
              placeholder="Enter notes about this lab panel"
              id={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(event) => field.handleChange(event.target.value)}
            />
          </FormControl>
        )}
      ></form.Field>
    </div>
  );
}
