import { CreateAppointmentDTO } from '@aster/shared/dtos/appointment';

const AppointmentOptions: {
  text: string;
  value: CreateAppointmentDTO['type'];
}[] = [
  {
    text: 'Initial Visit',
    value: 'initial_visit',
  },
  {
    text: 'Prenatal',
    value: 'prenatal',
  },
  {
    text: 'Return OB',
    value: 'return_ob',
  },
  {
    text: 'Return GYN',
    value: 'return_gyn',
  },
  {
    text: 'Prob GYN',
    value: 'prob_gyn',
  },
  {
    text: 'Postpartum',
    value: 'post_partum',
  },
  {
    text: 'Block',
    value: 'block',
  },
  {
    text: 'General',
    value: 'general',
  },
  {
    text: 'Lactation',
    value: 'lactation',
  },
  {
    text: 'Newborn Visit',
    value: 'newborn_visit',
  },
  {
    text: 'Labs Only',
    value: 'labs_only',
  },
  {
    text: 'Consultation',
    value: 'consultation',
  },
  {
    text: 'Home Visit',
    value: 'home_visit',
  },
];

export default AppointmentOptions;
