import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { useDownloadLabOrderLabelsMutation } from '../mutations/download-lab-order-labels.mutation';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { InputMask } from '@react-input/mask';
import { Label } from '@aster/client/ui/Label/Label';
import { Button } from '@aster/client/ui/Button/Button';
import { Input } from '@aster/client/ui/Input/Input';
import { FormError } from '@aster/client/ui/FormControl/FormError';

export type DownloadLabOrderLabelsProps = {
  open: boolean;
  handleClose: () => void;
  orderID: string;
};

function DownloadLabOrderLabels({
  open,
  handleClose,
  orderID,
}: DownloadLabOrderLabelsProps) {
  const { downloadLabOrderLabelsMutation } =
    useDownloadLabOrderLabelsMutation(handleClose);
  const form = useForm({
    defaultValues: {
      orderID,
      collectionDate: '',
      numberOfLabels: 5,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      await downloadLabOrderLabelsMutation.mutateAsync(value);
    },
  });

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={'xs'}
      onClose={() => void handleClose()}
      aria-describedby="alert-dialog-slide-description"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center text-h4 font-semibold mt-5">
            Download lab order labels
          </div>

          <DialogContent className="bg-white w-full">
            <div className=" flex flex-col items-center">
              <form.Field
                name="collectionDate"
                validators={{ onBlur: z.coerce.date() }}
                children={(field) => (
                  <FormControl className="mb-4">
                    <Label>When was the sample collected?</Label>
                    <InputMask
                      component={Input}
                      name={field.name}
                      mask="MM/DD/YYYY"
                      separate
                      showMask
                      onBlur={field.handleBlur}
                      replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                      value={field.state.value}
                      onChange={(event) =>
                        field.handleChange(event.target.value)
                      }
                      placeholder="MM/DD/YYYY"
                    />
                    <FormError id={`err-${field.name}`}>
                      {field.state.meta.errors?.join('\r')}
                    </FormError>
                  </FormControl>
                )}
              ></form.Field>
            </div>
          </DialogContent>
        </div>
        <DialogActions className=" bg-white flex flex-col items-center">
          <form.Subscribe
            selector={(state) => [
              state.canSubmit,
              state.isSubmitting,
              state.isFieldsValidating,
            ]}
            children={([canSubmit, isSubmitting, isValidating]) => (
              <div className="w-[250px] sm:w-[400px]">
                <Button
                  type="submit"
                  disabled={!canSubmit}
                  isLoading={isSubmitting || isValidating}
                >
                  Download Labels
                </Button>
              </div>
            )}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DownloadLabOrderLabels;
