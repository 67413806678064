import Typography from '../../../components/Typography';
import {
  CommunicationPreferencesFields,
  useCommunicationPreferencesForm,
} from '../hooks/useCommunicationPreferencesForm';
import { CircularProgress } from '@mui/material';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  defaultPreferredCommunicationMethods,
  defaultSpecialNeeds,
} from '../defaults';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { LAST_SAVED_PREFERENCES_INFORMATION } from '../constants';
import dayjs from 'dayjs';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useState } from 'react';

const Preferences = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        communicationPreferencesForm.handleSubmit();
      },
    }));

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(
      LAST_SAVED_PREFERENCES_INFORMATION
    );
    const lastSavedString = lastSaved
      ? `Last saved 
    ${dayjs().diff(dayjs(lastSaved), 'minutes')}
     minutes ago`
      : '';

    const communicationPreferencesForm = useCommunicationPreferencesForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save(value)?.then(() => {
          storePreference(
            LAST_SAVED_PREFERENCES_INFORMATION,
            dayjs().toISOString()
          );
          setDebounceValue(1000);
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: CommunicationPreferencesFields) => {
        await updatePatientMutation.mutateAsync(value);
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={communicationPreferencesForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                communicationPreferencesForm.handleSubmit();
              }}
            >
              <div className="flex flex-wrap h-fit gap-10 xl:gap-40">
                <div className="flex flex-col gap-y-5">
                  <communicationPreferencesForm.Field
                    name="preferredCommunicationMethods"
                    children={(field) => (
                      <FormControl className="flex flex-col gap-2">
                        <Typography
                          text="Preferred Communication Methods"
                          variant="h5"
                          customClass="font-semibold"
                        ></Typography>
                        <div className="flex flex-col gap-2">
                          <Label>
                            Please select your preferred communication methods;
                            you can change these anytime by contacting us.
                          </Label>
                          {defaultPreferredCommunicationMethods.map(
                            (method) => (
                              <div
                                key={method.value}
                                className="flex gap-2 relative"
                              >
                                <Checkbox
                                  id={`${field.name}.${method.value}`}
                                  key={method.value}
                                  checked={
                                    field.state.value?.find(
                                      (item) => item.value === method.value
                                    )?.checked
                                  }
                                  onCheckedChange={(value) => {
                                    if (value === 'indeterminate') return;
                                    const updatedItems = field.state.value?.map(
                                      (item) =>
                                        item.value === method.value
                                          ? { ...item, checked: value }
                                          : item
                                    );
                                    if (updatedItems) {
                                      field.handleChange(updatedItems);
                                      communicationPreferencesForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                                <Label
                                  htmlFor={`${field.name}.${method.value}`}
                                  className="cursor-pointer"
                                >
                                  {method.text}
                                </Label>
                              </div>
                            )
                          )}
                        </div>
                      </FormControl>
                    )}
                  />
                  <communicationPreferencesForm.Field
                    name="specialNeeds"
                    children={(field) => (
                      <FormControl className="flex flex-col gap-2">
                        <Typography
                          text="Accessibility"
                          variant="h5"
                          customClass="font-semibold"
                        ></Typography>
                        <div className="flex flex-col gap-2">
                          <Label>
                            Is there any special assistance or accommodations
                            you require for your appointments?
                          </Label>
                          {defaultSpecialNeeds.map((method) => (
                            <div
                              key={method.value}
                              className="flex gap-2 relative"
                            >
                              <Checkbox
                                id={`${field.name}.${method.value}`}
                                key={method.value}
                                checked={
                                  field.state.value?.find(
                                    (item) => item.value === method.value
                                  )?.checked
                                }
                                onCheckedChange={(value) => {
                                  if (value === 'indeterminate') return;
                                  const updatedItems = field.state.value?.map(
                                    (item) =>
                                      item.value === method.value
                                        ? { ...item, checked: value }
                                        : item
                                  );
                                  if (updatedItems) {
                                    field.handleChange(updatedItems);
                                    communicationPreferencesForm.handleSubmit();
                                  }
                                }}
                                disabled={readonly}
                              />
                              <Label
                                htmlFor={`${field.name}.${method.value}`}
                                className="cursor-pointer"
                              >
                                {method.text}
                              </Label>
                            </div>
                          ))}
                        </div>
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default Preferences;
