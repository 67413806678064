import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';

import { ExitToAppOutlined, FilterAltOutlined } from '@mui/icons-material';

import { LedgerRecordDTO } from '@aster/shared/dtos/billing';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';

import { useAddRecordMutation } from './mutations/use-add-record';
import { useAddRecordAdjustmentMutation } from './mutations/use-add-record-adjustment';

import { LedgerRecords } from './LedgerRecords';
import {
  AddAdjustmentDialog,
  AddAdjustmentFormDialogHandle,
} from './modals/AddAdjustmentDialog';
import {
  AddRecordDialog,
  AddRecordFormDialogHandle,
} from './modals/AddRecordDialog';

import { useSearch } from './hooks/use-search';

import { useAuth } from '../../authentication/AuthProvider';

import ButtonType from '../../components/Button';
import SearchInput from '../../components/SearchInput';
import { useSnackbar } from '../../components/Snack';

export type LedgerProps = {
  records?: LedgerRecordDTO[];
  patients?: Pick<PatientInfoDTO, 'id' | 'firstName' | 'lastName'>[];
};

export type LedgerHandle = {
  addRecord: () => void;
  addFilter: () => void;
  export: () => void;
};

export const Ledger = forwardRef<LedgerHandle, LedgerProps>(
  (
    { records: originalRecords = [], patients: allPatients = [] }: LedgerProps,
    ref
  ) => {
    const { isAsterAdmin } = useAuth();
    const { patient } = useParams();

    const DEFAULT_PATIENT_ID = patient ?? null;

    const { showMessage } = useSnackbar();

    const [search, setSearch] = useState('');

    const records = useSearch(originalRecords, search, (item) =>
      [item.patientMetadata.firstName, item.patientMetadata.lastName].join(' ')
    );

    const [selectedPatientId, setSelectedPatientId] = useState<
      LedgerRecordDTO['patientMetadata']['id'] | null
    >(patient ?? null);
    const selectedPatient = useMemo<LedgerRecordDTO['patientMetadata'] | null>(
      () =>
        allPatients.find((patient) => patient.id === selectedPatientId) ?? null,
      [allPatients, selectedPatientId]
    );

    const [selectedRecordId, setSelectedRecordId] = useState<
      LedgerRecordDTO['id'] | null
    >(null);
    const selectedRecord = useMemo<LedgerRecordDTO | null>(
      () => records.find((record) => record.id === selectedRecordId) ?? null,
      [records, selectedRecordId]
    );

    const [openedModal, setOpenedModal] = useState<
      'CHARGE' | 'ADJUSTMENT' | null
    >(null);

    const addRecordDialogFormRef = useRef<AddRecordFormDialogHandle>(null);
    const addAdjustmentDialogFormRef =
      useRef<AddAdjustmentFormDialogHandle>(null);

    const addRecordMutation = useAddRecordMutation({
      onSuccess: () => {
        setOpenedModal(null);
        addRecordDialogFormRef.current?.reset();
      },
    });

    const addRecordAdjustmentMutation = useAddRecordAdjustmentMutation({
      onSuccess: () => {
        setOpenedModal(null);
        addAdjustmentDialogFormRef.current?.reset();
      },
    });

    useImperativeHandle(ref, () => ({
      addRecord: () => setOpenedModal('CHARGE'),
      addFilter: () =>
        showMessage({
          type: 'info',
          message: 'Filters will be available soon!',
        }),
      export: () =>
        showMessage({
          type: 'info',
          message: 'Exports will be available soon!',
        }),
    }));

    return (
      <div>
        {!patient && (
          <div className="flex gap-2.5 mb-8 justify-end">
            <SearchInput
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
              placeholder="Search by number or name..."
            />
            {isAsterAdmin && (
              <>
                <ButtonType
                  variant="outlined"
                  text="Add Filter"
                  onClick={() =>
                    showMessage({
                      type: 'info',
                      message: 'Filters will be available soon!',
                    })
                  }
                  icon
                  specificIcon={<FilterAltOutlined />}
                  notRounded
                />
                <ButtonType
                  variant="outlined"
                  text="Export"
                  onClick={() =>
                    showMessage({
                      type: 'info',
                      message: 'Exports will be available soon!',
                    })
                  }
                  icon
                  specificIcon={<ExitToAppOutlined />}
                  notRounded
                />
              </>
            )}
          </div>
        )}
        <LedgerRecords
          showPatientColumn={allPatients.length > 1 || !patient}
          records={records}
          onAdjustmentClick={(id) => {
            setSelectedRecordId(id);
            setOpenedModal('ADJUSTMENT');
          }}
        />
        <AddRecordDialog
          ref={addRecordDialogFormRef}
          patient={selectedPatient}
          patients={allPatients}
          open={openedModal === 'CHARGE'}
          showDropdown={!patient}
          onClose={() => {
            setOpenedModal(null);
            setSelectedPatientId(DEFAULT_PATIENT_ID);
            addRecordDialogFormRef.current?.reset();
          }}
          onPatientChange={(patientID) => setSelectedPatientId(patientID)}
          onSubmit={addRecordMutation.mutate}
          isLoading={addRecordMutation.isPending}
        />
        <AddAdjustmentDialog
          ref={addAdjustmentDialogFormRef}
          record={selectedRecord}
          open={openedModal === 'ADJUSTMENT'}
          onClose={() => {
            setOpenedModal(null);
            setSelectedRecordId(null);
            addAdjustmentDialogFormRef.current?.reset();
          }}
          onSubmit={addRecordAdjustmentMutation.mutate}
          isLoading={addRecordAdjustmentMutation.isPending}
        />
      </div>
    );
  }
);
