import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { useFrontChat } from '../lib/hooks/use-front-chat';

const FRONT_CHAT_ROUTES = [
  '/',
  '/signup',
  '/calendar',
  '/patients',
  '/messages',
  '/forms',
  '/payments',
  '/workflows',
  '/programs',
  '/documents',
  '/analytics',
  '/settings',
  '/prescription',
  '/members',
  '/signin',
  '/practice',
  '/efax',
];

const chatId = import.meta.env.VITE_FRONT_CHAT_ID;

type FrontChatProviderProps = {
  children: React.ReactNode;
};

// This provider wraps around the exposed source code from `front-chat-sdk`
// as they don't provide an NPM package that can be installed as dependency
// into the project.
//
// The decision to go with their extra lib code compared to simply using an
// autoload tag is to have extra checks on the service's runtime status, as
// we'll be enabling and disabling the module dynamically based on the page
// URL.
//
// See: https://github.com/frontapp/front-chat-sdk
export const FrontChatProvider = ({ children }: FrontChatProviderProps) => {
  const { pathname } = useLocation();
  const { isInitialized, initialize, shutdown } = useFrontChat();

  useEffect(() => {
    if (FRONT_CHAT_ROUTES.includes(pathname) && !isInitialized)
      initialize?.({ chatId });
    else if (!FRONT_CHAT_ROUTES.includes(pathname) && isInitialized)
      shutdown?.();
  }, [pathname, isInitialized, initialize, shutdown]);

  return children;
};
