import { signOut } from 'aws-amplify/auth';

import { useAuth } from '../../authentication/AuthProvider';
import { PricingTable } from '../../integrations/stripe/components/PricingTable';

import { Button } from '@aster/client/ui/Button/Button';

const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const pricingTableId = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID;

export const Checkout = () => {
  const { profile } = useAuth();

  return (
    <div className="w-full h-screen text-center content-center space-y-4">
      <div className="text-center">
        <div className="max-w-[632px] mx-auto text-left">
          <Button variant="link" onClick={() => signOut()}>
            Sign out
          </Button>
        </div>
      </div>
      <PricingTable
        pricingTableId={pricingTableId}
        publishableKey={publishableKey}
        clientReferenceId={profile?.practiceId}
      />
    </div>
  );
};
