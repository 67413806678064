import Tag from '../../../components/Tag';
import { useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '../../../components/Typography';
import { usePatientProfileQuery } from '../../notes/queries/patient-profile.query';

export const PatientInfo = () => {
  const { patient } = useParams();
  const { patientProfile, isPatientProfileLoading } =
    usePatientProfileQuery(patient);

  if (isPatientProfileLoading || !patientProfile) {
    return <CircularProgress size="1.5rem" />;
  }
  return (
    <div className="flex flex-col gap-[0.69rem] bg-grayCard pt-3 pl-4 pr-2 pb-[0.88rem] rounded-lg">
      <div className="flex justify-between items-center">
        <Typography
          variant="h6"
          text={`${patientProfile.name}, ${patientProfile.age}`}
        />
      </div>
      <Tag
        color="neutral-200"
        tagText="Email"
        rightText={patientProfile.email}
      />
      <Tag
        tagText="Number"
        color="neutral-200"
        rightText={patientProfile.phoneNumber ?? 'N/A'}
      />
    </div>
  );
};
