import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../theme';
import Typography from '../../components/Typography';
import { useSendIntakeFormMutation } from './mutations/send-intake-form.mutation';
import { useParams } from 'react-router';
import { usePatientInfoQuery } from './queries/patient-info.query';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';

interface PatientOptionsMenuProps {
  className?: string;
  openDeletePatientModal: (value: boolean | undefined) => void;
  openUnarchivePatientModal: (value: boolean | undefined) => void;
}

const PatientOptionsMenu = ({
  className,
  openDeletePatientModal,
  openUnarchivePatientModal,
}: PatientOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { patient } = useParams<{ patient: string }>();

  const { patientInfo } = usePatientInfoQuery(patient);

  const { sendIntakeFormMutation } = useSendIntakeFormMutation(
    patientInfo?.email
  );

  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SettingsOutlinedIcon sx={{ color: colors.gray }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {patientInfo && patientInfo.status === 'active' ? (
          <>
            {patientInfo && patientInfo.email && (
              <MenuItem
                onClick={() => sendIntakeFormMutation.mutate(patient as string)}
              >
                <Typography
                  variant="bodySmall"
                  customClass="ml-2"
                  text="Resend intake form"
                />
              </MenuItem>
            )}
            <MenuItem onClick={() => openDeletePatientModal(true)}>
              <Typography
                variant="bodySmall"
                customClass="text-red-500 ml-2"
                text="Archive patient"
              />
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => openUnarchivePatientModal(true)}>
            <Typography
              variant="bodySmall"
              customClass="text-red-500 ml-2"
              text="Unarchive patient"
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default PatientOptionsMenu;
