import { Navigate, Outlet, Route, Routes } from 'react-router';
import SignUp from './SignUp';
import SignIn from './SignIn';
import Verify from './Verify';
import ForgotPassword from './ForgotPassword';
import Upgrade from './Upgrade';
import PublicForms from '../features/public-forms/PublicForms';
import AgreeToLabConsents from '../features/public-forms/AgreeToLabConsents';
import Error from '../pages/Error';
import ErrorBoundary from '../components/ErrorBoundary';

const UnauthenticatedRouter = () => {
  const ErrorWrapper = () => (
    <ErrorBoundary fallback={<Error type={500} />}>
      <Outlet />
    </ErrorBoundary>
  );

  return (
    <Routes>
      <Route element={<ErrorWrapper />}>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/upgrade" element={<Upgrade />} />
        <Route path="/guest/consent/:token" element={<AgreeToLabConsents />} />
        <Route path="/guest/form/:token" element={<PublicForms />} />
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Route>
    </Routes>
  );
};

export default UnauthenticatedRouter;
