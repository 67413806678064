import MembersAndBillingPage from '../features/members/MembersAndBilling';

const MembersAndBilling = () => {
  return (
    <div className="h-screen">
      <MembersAndBillingPage />
    </div>
  );
};

export default MembersAndBilling;
