import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../components/Button';
import Typography from '../../components/Typography';
import { UseMutationResult } from '@tanstack/react-query';
import { Button } from '@aster/client/ui/Button/Button';

type ModalProps = {
  patientID: string;
  open: boolean;
  handleConfirm: UseMutationResult<any, unknown, string, unknown>;
  handleClose: () => void;
};

const ConfirmUnarchivePatientModal = ({
  patientID,
  open,
  handleConfirm,
  handleClose,
}: ModalProps) => (
  <Dialog
    open={open}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
    className="p-2"
    sx={{
      '& .MuiDialog-container': {
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '400px',
        },
      },
    }}
  >
    <DialogContent className="flex flex-col items-center text-center">
      <Typography
        variant={'h4'}
        customClass="font-semibold my-5"
        text={'Unarchive this patient?'}
      />
      <Typography
        variant="body"
        customClass="mb-2.5 text-left"
        text={
          "After unarchiving this patient, you will be able to edit this patient's existing information."
        }
      />
    </DialogContent>
    <DialogActions>
      <Button
        variant={'text'}
        onClick={() => {
          handleClose();
        }}
      >
        Cancel
      </Button>
      <Button
        isLoading={handleConfirm.status === 'pending'}
        variant={'default'}
        onClick={() => {
          handleConfirm.mutate(patientID);
        }}
      >
        Unarchive
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmUnarchivePatientModal;
