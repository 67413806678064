import TryFirst from '../../assets/icons/TryFirst';
import UserPlus from '../../assets/icons/UserPlus';
import ButtonType from '../../components/Button';
import CalendarPlus from '../../assets/icons/CalendarPlus';
import BookSpells from '../../assets/icons/BookSpells';
import { useNavigate } from 'react-router';
import Typography from '../../components/Typography';
import { Card } from './HomeCard';
import midwife from '../../assets/empty-images/home-illustration.webp';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { useAuth } from '../../authentication/AuthProvider';

function getTimeOfDay() {
  const currentHour = dayjs().hour();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
}

export default function () {
  const navigate = useNavigate();
  const { profile } = useAuth();
  return (
    <div className="flex flex-col w-full items-center justify-center py-24">
      <div className="flex items-center">
        <div className="flex flex-col">
          <Typography
            variant="h3"
            text={`${getTimeOfDay()} ${profile?.firstName},`}
          />
          <Typography
            variant="h3"
            text="Welcome to Aster!"
            customClass="font-normal"
          />
        </div>
        <img src={midwife} alt="Midwife" className="w-80" />
      </div>
      <Typography
        variant="body"
        text="Congratulations on taking the first step towards streamlining your practice with Aster. You can now focus on providing the best care! Let’s get started:"
        customClass="text-gray-500 text-center max-w-[68ch]"
      />

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mt-16">
        <div className="flex-1 relative">
          <div className="absolute -top-10 left-[-55px] ">
            <TryFirst />
          </div>
          <Card
            icon={<UserPlus />}
            title="Add new clients"
            description="Start with just a name and email. Additional details can be added whenever you're ready."
            button={
              <ButtonType
                variant="contained"
                text="Client"
                onClick={() => navigate('/patients')}
                icon
                classes="w-full mt-auto"
                specificIcon={<AddIcon className="text-white mr-2" />}
              />
            }
          />
        </div>

        <div className="flex-1">
          <Card
            icon={<CalendarPlus />}
            title="Schedule appointments"
            description="Use our intuitive calendar to schedule appointments, or even a calendar block for some me time."
            button={
              <ButtonType
                variant="contained"
                text="Appointment"
                onClick={() => navigate('/calendar')}
                classes="w-full mt-auto w-[210px]"
                icon
                specificIcon={<AddIcon className="text-white mr-2" />}
              />
            }
          />
        </div>
        <div className="flex-1">
          <Card
            icon={<BookSpells />}
            title="Take secure notes"
            description="Quickly document encounters using our template options: Basic, SOAP, Prenatal, among others."
            button={
              <ButtonType
                variant="contained"
                text="Start with a client"
                onClick={() => navigate('/patients')}
                classes="w-full mt-auto w-[210px]"
              />
            }
          />
        </div>
      </div>
      <div className=" max-w-[830px] rounded-[35px] p-6 flex flex-col sm:flex-row mt-12 gap-4 items-center bg-grayBackground gap-4">
        <div className="flex flex-col gap-4">
          <Typography variant="h6">Learn how to use Aster</Typography>
          <Typography variant="body" customClass="text-asterGray">
            We have a rich{' '}
            <a
              className="underline"
              href="https://www.youtube.com/playlist?list=PLCnTIDSXZtLpOjyaH0wylIZYbOTYhc-cl"
            >
              video library
            </a>{' '}
            and{' '}
            <a className="underline" href="https://help.astercare.com/">
              help center
            </a>{' '}
            where you can take a self-guided onboarding on how to use Aster.
            From adding your first patient to scheduling an appointment and
            everything in between.
          </Typography>
          <Typography variant="body" customClass="text-asterGray">
            If you need any support, please email <b>hello@astercare.com</b>
          </Typography>
        </div>
        <iframe
          width="300"
          height="168"
          src="https://www.youtube.com/embed/videoseries?si=OwwqHz7lFU01C3bG&amp;list=PLCnTIDSXZtLpOjyaH0wylIZYbOTYhc-cl"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
  );
}
