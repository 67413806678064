import dayjs from 'dayjs';

const timeSinceLastSaved = (lastSaved: string) => {
  const minutesSinceLastSaved = dayjs().diff(dayjs(lastSaved), 'minutes');
  if (minutesSinceLastSaved < 60) {
    return `Last saved ${minutesSinceLastSaved} minutes ago`;
  }
  if (minutesSinceLastSaved < 1440) {
    return `Last saved ${Math.floor(minutesSinceLastSaved / 60)} hours ago`;
  }
  if (minutesSinceLastSaved < 2880) {
    return `Last saved yesterday`;
  }
  return `Last saved ${Math.floor(minutesSinceLastSaved / 1440)} days ago`;
};

export default timeSinceLastSaved;
