import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  AddLedgerRecordAdjustmentInput,
  LedgerRecordDTO,
} from '@aster/shared/dtos/billing';

import axios from '../../../app/axiosConfig';

import { ExtendedError } from '../../../components/ErrorBoundary';
import { useSnackbar } from '../../../components/Snack';

import { useParams } from 'react-router';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';

const addAdjustment = async ({
  id,
  patientID,
  adjustment,
}: AddLedgerRecordAdjustmentInput) => {
  const { data: record } = await axios.put(`/v2/billing/${id}`, {
    patientID,
    adjustment,
  });

  return record;
};

export const useAddRecordAdjustmentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const { patient } = useParams();

  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();

  const ledgerQueryKey = ['ledger', ...(patient ? [patient] : [])];

  const addAdjustmentMutation = useMutation({
    mutationKey: ['addAdjustment'],
    mutationFn: addAdjustment,
    scope: {
      id: createMutationScopeID(MutationScope.BILLING),
    },
    onMutate: async (record: AddLedgerRecordAdjustmentInput) => {
      const previousFetchedRecords = queryClient.getQueryData(ledgerQueryKey);

      queryClient.setQueryData<LedgerRecordDTO[]>(ledgerQueryKey, (old) => {
        if (!old) return [];

        return old.map((previousRecord) => {
          return {
            ...previousRecord,
            adjustment:
              previousRecord.id === record.id
                ? record.adjustment
                : previousRecord.adjustment,
          };
        });
      });

      return { previousFetchedRecords };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ledgerQueryKey,
      });

      showMessage({
        type: 'success',
        message: 'Adjustment added successfully!',
      });

      onSuccess?.();
    },
    onError: (error, _, context) => {
      queryClient.setQueryData<LedgerRecordDTO[]>(
        ledgerQueryKey,
        context?.previousFetchedRecords as LedgerRecordDTO[]
      );

      const ledgerRecordAdjustmentError: ExtendedError = new Error(
        error.message
      );

      ledgerRecordAdjustmentError.name = `LedgerRecordAdjustmentError`;
      ledgerRecordAdjustmentError.stack = error.stack as string | undefined;
      ledgerRecordAdjustmentError.cause = error;

      datadogRum.addError(ledgerRecordAdjustmentError);

      showMessage({
        type: 'error',
        message:
          'An error occurred while updating the record. Please try again!',
      });

      onError?.();
    },
  });

  return addAdjustmentMutation;
};
