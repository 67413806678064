import CircularProgress from '@mui/material/CircularProgress';
import { LabTestCard } from './LabTestCard';
import { useAvailableLabTests } from '../queries/query-available-lab-tests';

export const LabTests = () => {
  const { areLabTestsLoading, filteredLabTests } = useAvailableLabTests();
  if (areLabTestsLoading) {
    return <CircularProgress className="mx-auto" size="1.5rem" />;
  }

  return (
    <div className="grid grid-cols-2 gap-3 w-full overflow-auto max-h-96">
      {filteredLabTests?.map((labTest) => (
        <LabTestCard key={labTest.id} labTest={labTest} />
      ))}
    </div>
  );
};
