import Typography from '../../../components/Typography';
import { CircularProgress } from '@mui/material';
import { Input } from '@aster/client/ui/Input/Input';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  ContactInfoFields,
  useContactInfoForm,
} from '../hooks/useContactInfoForm';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { GoogleAutoComplete } from './GoogleAutoComplete';
import { getAddressFieldsFromGoogle } from '../utils/get-address-fields-from-google';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import dayjs from 'dayjs';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { LAST_SAVED_CONTACT_INFORMATION } from '../constants';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';

const ContactInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        contactInfoForm.handleSubmit();
      },
    }));

    const updateContactAddress = (
      place: google.maps.places.PlaceResult,
      ref: React.RefObject<HTMLInputElement>
    ) => {
      const addressFields = getAddressFieldsFromGoogle(place);
      if (!addressFields) return;
      const { streetAddress1, city, state, zipCode } = addressFields;
      contactInfoForm.state.values.streetAddress1 = streetAddress1;
      contactInfoForm.state.values.city = city;
      contactInfoForm.state.values.state = state;
      contactInfoForm.state.values.zipCode = zipCode;
    };

    const streetAddrees1Ref = useRef<HTMLInputElement>(null);

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_CONTACT_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const contactInfoForm = useContactInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        save(value)?.then(() => {
          storePreference(
            LAST_SAVED_CONTACT_INFORMATION,
            dayjs().toISOString()
          );
          setDebounceValue(1000);
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: Partial<ContactInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={contactInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                contactInfoForm.handleSubmit();
              }}
            >
              <div className="flex flex-wrap h-fit gap-10 xl:gap-40">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Contact Information"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <contactInfoForm.Field
                    name="phoneNumber"
                    children={(field) => (
                      <FormControl>
                        <Label>Phone</Label>
                        <Input
                          id="phoneNumber"
                          placeholder="(xxx) xxx-xxxx"
                          name="phoneNumber"
                          value={field.state.value}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              contactInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                  <contactInfoForm.Field
                    name="email"
                    children={(field) => (
                      <FormControl>
                        <Label>Email</Label>
                        <Input
                          id="email"
                          name="email"
                          value={field.state.value}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              contactInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-y-5 w-full sm:w-auto w-full sm:w-auto">
                  <Typography
                    text="Address"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <contactInfoForm.Field
                    name="streetAddress1"
                    children={(field) => (
                      <FormControl>
                        <Label>Address Line 1</Label>
                        <GoogleAutoComplete
                          inputProps={{
                            className: 'bg-white',
                            value: field.state.value ?? '',
                            onChange: (evt) =>
                              field.handleChange(evt.target.value),
                            disabled: readonly,
                            onBlur: (evt) => {
                              if (field.state.meta.isDirty) {
                                contactInfoForm.handleSubmit();
                              }
                            },
                          }}
                          selectedAutocomplete={(place) => {
                            updateContactAddress(place, streetAddrees1Ref);
                            contactInfoForm.handleSubmit();
                          }}
                          ref={streetAddrees1Ref}
                        />
                      </FormControl>
                    )}
                  />
                  <contactInfoForm.Field
                    name="streetAddress2"
                    children={(field) => (
                      <FormControl>
                        <Label>Address Line 2</Label>
                        <Input
                          id="streetAddress2"
                          placeholder="Enter address"
                          name="streetAddress2"
                          value={field.state.value}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              contactInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                  <contactInfoForm.Field
                    name="city"
                    children={(field) => (
                      <FormControl>
                        <Label>City</Label>
                        <Input
                          id="city"
                          placeholder="Enter city"
                          name="city"
                          value={field.state.value}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              contactInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                  <contactInfoForm.Field
                    name="state"
                    children={(field) => (
                      <FormControl>
                        <Label>State</Label>
                        <Input
                          id="state"
                          placeholder="Enter state"
                          name="state"
                          value={field.state.value}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              contactInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                  <contactInfoForm.Field
                    name="zipCode"
                    children={(field) => (
                      <FormControl>
                        <Label>Zip Code</Label>
                        <Input
                          id="zipCode"
                          placeholder="Enter zip code"
                          name="zipCode"
                          value={field.state.value}
                          onChange={(evt) =>
                            field.handleChange(evt.target.value)
                          }
                          onBlur={(evt) => {
                            if (field.state.meta.isDirty) {
                              contactInfoForm.handleSubmit();
                            }
                          }}
                          disabled={readonly}
                        />
                      </FormControl>
                    )}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
);

export default ContactInformation;
