import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import PrenatalNote from '../features/notes/PrenatalNote/PrenatalNote';
import BasicNote from '../features/notes/BasicNote/BasicNote';
import SoapNote from '../features/notes/SoapNote/SoapNote';
import BirthNote from '../features/notes/BirthNote';
import { useEncounterDetailQuery } from '../features/notes/queries/encounter-detail.query';

const Notes = () => {
  const { patient, encounterId } = useParams();

  const { encounterData, isEncounterLoading } = useEncounterDetailQuery(
    encounterId as string
  );

  if (isEncounterLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  if (patient && encounterData) {
    switch (encounterData.templateType) {
      case 'basic':
        return <BasicNote />;
      case 'birthflow':
        return <BirthNote />;
      case 'prenatal':
        return <PrenatalNote />;
      case 'soap':
        return <SoapNote />;
      default:
        return <div>No note found for the given template type</div>;
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <CircularProgress />
    </div>
  );
};

export default Notes;
